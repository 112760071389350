import React, { useEffect, useState } from "react";
import SearchBar from "./SearchBar";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../..";
import {
  SET_CUSTOMER_DATA,
  SET_IS_LOADING,
  SET_IS_READY,
} from "../../../Features/customer";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import { createNewBaseService } from "../../../assets/BaseService";
import { Autocomplete, Modal, TextField } from "@mui/material";
import {
  SET_SELECTEDHEADER,
  SET_VISUAL_HEADER,
} from "../../../Features/rightBarHeader";
import {
  FaCalendarAlt,
  FaCheckCircle,
  FaEnvelope,
  FaExclamationTriangle,
  FaMapMarkerAlt,
  FaMapPin,
  FaPhone,
  FaStar,
  FaTimesCircle,
} from "react-icons/fa";
import { SET_ARROWKEYLEVEL, SET_FLAVOURS_AND_TOPPINGS_OPEN, SET_ITERATOR, SET_POPUPITERATOR, SET_POPUPSECTION, SET_TOPLEVELCATEGORY } from "../../../Features/ItemsStates";
import { handleOpen } from "../../../Features/cart";
import ComplaintModal from "../Orders/Modal/ComplaintModal";
import { IoMdClose } from "react-icons/io";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function FilePenIcon(props: any) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M12 22h6a2 2 0 0 0 2-2V7l-5-5H6a2 2 0 0 0-2 2v10" />
      <path d="M14 2v4a2 2 0 0 0 2 2h4" />
      <path d="M10.4 12.6a2 2 0 1 1 3 3L8 21l-4 1 1-4Z" />
    </svg>
  );
}

function TrashIcon(props: any) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M3 6h18" />
      <path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6" />
      <path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2" />
    </svg>
  );
}

function FlameIcon(props: any) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="#43A047"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M8.5 14.5A2.5 2.5 0 0 0 11 12c0-1.38-.5-2-1-3-1.072-2.143-.224-4.054 2-6 .5 2.5 2 4.9 4 6.5 2 1.6 3 3.5 3 5.5a7 7 0 1 1-14 0c0-1.153.433-2.294 1-3a2.5 2.5 0 0 0 2.5 2.5z" />
    </svg>
  );
}

const Customers = () => {
  const [open, setOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState<string>("Orders");
  const [phone, setPhone] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openComplainsModal, setComplainsModal] = useState<boolean>(false);
  const [addAddressPopup, setAddAddressPopup] = useState<boolean>(false);
  const [newAddress, setNewAddress] = useState<string | undefined>(undefined);
  const branchName = useSelector(
    (state: RootState) => state.cart.loginData.branchName
  );
  const [address, setAddress] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [area, setArea] = useState<string>("");

  const darkMode = useSelector((state: RootState) => state.leftHeader.darkMode);
  const pos_config = useSelector(
    (state: RootState) => state.leftHeader.pos_config
  );
  const customer = useSelector((state: RootState) => state.customer);
  const token = localStorage.getItem("access_token");
  const dispatch = useDispatch();
  const [selectedOrderDetails, setSelectedOrderDetails] = useState<any | null>(
    null
  );
  const [selectedComplainDetails, setSelectedComplainDetails] = useState<
    any | null
  >(null);
  const NewBaseService = createNewBaseService(pos_config);
  const [selectedCity, setSelectedCity] = useState<string>("");
  const [selectedArea, setSelectedArea] = useState<string>("");
  const [cities, setCities] = useState<string[]>([]);
  const [areas, setAreas] = useState<string[]>([]);
  const [selectedType, setSelectedType] = useState<string>("Home");
  const addressTypes = ["Home", "Work", "Other"];
  const [selectedNearestLandmark, setSelectedNearestLandmark] =
    useState<string>("");

  const [complaintModalOpen, setComplaintModalOpen] = useState<boolean>(false);
  const [selectedOrderId, setSelectedOrderId] = useState<string>("");

  const onChangeFunction = (e: any) => {
    const onlyNums = e.target.value.replace(/[^0-9]/g, "");
    if (onlyNums.length <= 11) setPhone(onlyNums);
  };

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const fetchCities = async () => {
    try {
      const response = await NewBaseService.get(
        "/dynamic/query/custom?queryname=getcities"
      );
      setCities(response.data.data.map((city: { Name: string }) => city.Name));
    } catch (error) {
      console.error("Error fetching cities:", error);
    }
  };

  const fetchAreas = async (selectedCity: string) => {
    try {
      const response = await NewBaseService.get(
        `/dynamic/query/custom?queryname=getareas&City=${selectedCity}`
      );
      setAreas(response.data.data.map((area: any) => area.Area));
    } catch (error) {
      console.error("Error fetching areas:", error);
    }
  };

  const OrderDetailsModal = React.memo(
    ({
      open,
      onClose,
      orderDetails,
    }: {
      open: boolean;
      onClose: () => void;
      orderDetails: any;
    }) => {
      const darkMode = useSelector((state: RootState) => state.leftHeader.darkMode);
      return (
        <Modal
          open={open}
          onClose={onClose}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div className={`${darkMode ? "bg-gray-800 text-white" : "bg-white text-gray-800"} 
                       md:p-8 p-6 rounded-lg shadow-xl max-w-4xl mx-4 md:mx-auto max-h-[90vh] overflow-y-auto relative`}>
            <button
              onClick={onClose}
              className={`absolute right-2 top-2 p-2 rounded-full hover:bg-opacity-10 hover:bg-gray-500 transition-colors duration-200`}
            >
              <IoMdClose size={24} />
            </button>
            <div className="flex flex-col space-y-6 mb-8 border-b pb-6">
              <div className="flex justify-between items-center gap-6">
                <div className="flex flex-col">
                  <h2 className="text-3xl font-bold tracking-tight">Order Invoice</h2>
                  <span className="text-lg opacity-75 mt-1">#{orderDetails?.ID}</span>
                </div>


              </div>
            </div>

            {orderDetails && (
              <div className="grid grid-cols-1 gap-6">
                <div className="grid grid-cols-2 gap-8 border-b pb-4">
                  <div>
                    <h3 className="text-lg font-semibold mb-3">Order Information</h3>
                    <div className="space-y-2">
                      <p><span className="font-medium">Date:</span> {new Date(orderDetails.Created).toLocaleString()}</p>
                      <p><span className="font-medium">Order Type:</span> {orderDetails.OrderType}</p>
                      <p><span className="font-medium">Payment Method:</span> {orderDetails.PaymentType}</p>
                      <p><span className="font-medium">Payment Status:</span> {orderDetails.PaymentType || 'N/A'}</p>
                    </div>
                  </div>
                  <div>
                    <h3 className="text-lg font-semibold mb-3">Customer Details</h3>
                    <div className="space-y-2">
                      <p><span className="font-medium">Name:</span> {orderDetails.CustomerName || 'Walk-in Customer'}</p>
                      <p><span className="font-medium">Phone:</span> {orderDetails.CustomerMobile || 'N/A'}</p>
                      <p><span className="font-medium">OutletID:</span> {orderDetails.OutletID || 'N/A'}</p>
                      <p><span className="font-medium">Amount:</span> {orderDetails.OrderAmount || 'N/A'}</p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </Modal>
      );
    }
  );


  useEffect(() => {
    fetchCities();
  }, []);

  const fetchOrders = (ItemID: string) => {
    const clickedOrder = customer.data?.orders.find(
      (order: any) => order.ID === ItemID
    );
    setSelectedOrderDetails(clickedOrder);
  };

  const fetchComplains = (ItemID: string) => {
    const clickedComplain = customer.data?.complains.find(
      (complain: any) => complain.id === ItemID
    );
    setSelectedComplainDetails(clickedComplain);
  };

  useEffect(() => {
    dispatch(SET_IS_READY(false));
  }, []);

  const InfoItem = ({
    label,
    value,
    icon,
  }: {
    label: string;
    value: string;
    icon: JSX.Element;
  }) => (
    <div className="mb-2 flex items-center">
      {icon}
      <div className="ml-2">
        <p
          className={`text-sm font-medium ${darkMode ? "text-gray-400" : "text-gray-500"
            }`}
        >
          {label}
        </p>
        <p
          className={` ${darkMode ? "text-gray-200" : "text-gray-800"
            } font-semibold`}
        >
          {value}
        </p>
      </div>
    </div>
  );

  const resetForm = () => {
    setName("");
    setEmail("");
    setPhone("");
    setAddress("");
    setArea("");
    setCity("");
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    dispatch(SET_IS_LOADING(true));
    await NewBaseService.post(
      "/dynamic/create",
      {
        collection: "customer",
        data: {
          Name: name,
          Mobile: phone,
          Email: email,
          Address: address,
          Area: area,
          City: city,
          customerddress: [
            {
              Address: address,
              NearestLandmark: "",
              City: city,
              Area: area,
              type: "Home",
            },
          ],
          MemberPoints: 0,
          Orders: 0,
          customerRating: 0,
          orders: [],
          complains: [],
        },
      },
      {}
    ).then((res) => {
      getCustomers(phone);
      handleClick();
      resetForm();
    });
  };

  const numberSetter = (phoneNumber: string) => {
    setPhone(phoneNumber);
  };

  const getCustomers = async (inputValue: string) => {
    dispatch(SET_IS_LOADING(true));
    await NewBaseService.get(
      `/dynamic/query/get-customer-with-orders-by-phone-number?phoneNo=${inputValue}`,
      { params: { collection: "customers" } }
    ).then((res) => {
      let data = res.data.data[0];
      dispatch(SET_CUSTOMER_DATA(data));
      dispatch(SET_IS_READY(true));
    });
    dispatch(SET_IS_LOADING(false));
  };

  const handlePlaceOrder = () => {
    dispatch(SET_SELECTEDHEADER("Pickup"));
    dispatch(SET_VISUAL_HEADER("Delivery"));
  };

  return (

    <div className={`w-full h-full`}>
      <div className="w-full h-fit p-6 pb-2">
        <SearchBar numberSetter={numberSetter} />
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert
            onClose={handleClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            Customer Created!
          </Alert>
        </Snackbar>
        {customer.isLoading && (
          <div className="w-full flex items-center justify-center mt-8">
            <CircularProgress />
          </div>
        )}
        {!customer.isLoading && !customer.data && (
          <div className="w-full h-fit p-6 rounded-lg shadow-sm">
            <div
              className={`flex items-center justify-center px-4 py-2 border-b mb-4 ${darkMode ? "border-[#2b2c37]" : ""
                }`}
            >
              <h1
                className={`${darkMode ? "text-white" : "text-gray-800"
                  } font-semibold text-2xl`}
              >
                Enter Customer Details
              </h1>
            </div>
            <form
              className="md:mx-48 sm:mx-20 mx-8"
              action=""
              onSubmit={handleSubmit}
            >
              <div className="grid md:grid-cols-2 md:gap-6">
                <div className="relative z-0 w-full mb-5 group">
                  <input
                    type="text"
                    name="floating_name"
                    id="floating_name"
                    className={`block py-2.5 px-0 w-full text-sm  bg-transparent border-0 border-b-2  appearance-none focus:outline-none focus:ring-0  peer ${darkMode
                      ? "border-gray-600 focus:border-blue-500 text-white"
                      : "text-gray-900 border-gray-300 focus:border-blue-600"
                      }`}
                    placeholder=" "
                    required
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                  <label
                    htmlFor="floating_name"
                    className={`peer-focus:font-medium absolute text-sm duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 ${darkMode ? "text-gray-400" : "text-gray-500"
                      }`}
                  >
                    Enter Customer Name
                  </label>
                </div>
                <div className="relative z-0 w-full mb-5 group important">
                  <input
                    type="tel"
                    name="floating_phone"
                    id="floating_phone"
                    className={`block py-2.5 px-0 w-full text-sm  bg-transparent border-0 border-b-2  appearance-none focus:outline-none focus:ring-0  peer ${darkMode
                      ? "text-white focus:border-blue-500 border-gray-600"
                      : "text-gray-900 border-gray-300 focus:border-blue-600"
                      }`}
                    placeholder=" "
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    required
                  />
                  <label
                    htmlFor="floating_phone"
                    className={`peer-focus:font-medium absolute text-sm   duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 ${darkMode ? "text-gray-400" : "text-gray-500"
                      }`}
                  >
                    Enter Phone number
                  </label>
                </div>
                <div className="relative z-0 w-full mb-5 group">
                  <Autocomplete
                    options={cities}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Enter your City"
                        variant="standard"
                        InputLabelProps={{
                          className: darkMode
                            ? "text-gray-400 !important"
                            : "text-gray-500 !important",
                        }}
                        InputProps={{
                          ...params.InputProps,
                          className: `block py-2.5 px-0 w-full text-sm bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 ${darkMode
                            ? "text-white border-gray-600 focus:border-blue-500 !important"
                            : "text-gray-900 border-gray-300 focus:border-blue-600 !important"
                            } !important`,
                        }}
                      />
                    )}
                    value={city}
                    onChange={(event, newValue) => {
                      const selectedCity = newValue || "";
                      setCity(selectedCity);
                      fetchAreas(selectedCity);
                    }}
                  />
                </div>
                <div className="relative z-0 w-full mb-5 group">
                  <Autocomplete
                    options={areas}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Enter your Area"
                        variant="standard"
                        InputLabelProps={{
                          className: darkMode
                            ? "text-gray-400 !important"
                            : "text-gray-500 !important",
                        }}
                        InputProps={{
                          ...params.InputProps,
                          className: `block py-2.5 px-0 w-full text-sm bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 ${darkMode
                            ? "text-white border-gray-600 focus:border-blue-500 !important"
                            : "text-gray-900 border-gray-300 focus:border-blue-600 !important"
                            } !important`,
                        }}
                      />
                    )}
                    value={area}
                    onChange={(event, newValue) => {
                      setArea(newValue || "");
                    }}
                  />
                </div>
                <div className="relative z-0 w-full mb-5 group">
                  <input
                    type="email"
                    name="floating_email"
                    id="floating_email"
                    className={`block py-2.5 px-0 w-full text-sm  bg-transparent border-0 border-b-2  appearance-none focus:outline-none focus:ring-0  peer ${darkMode
                      ? "text-white focus:border-blue-500 border-gray-600"
                      : "text-gray-900 border-gray-300 focus:border-blue-600"
                      }`}
                    placeholder=" "
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  <label
                    htmlFor="floating_email"
                    className={`peer-focus:font-medium absolute text-sm   duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 ${darkMode ? "text-gray-400" : "text-gray-500"
                      }`}
                  >
                    Enter Customer Email
                  </label>
                </div>
                <div className="relative z-0 w-full mb-5 group">
                  <input
                    type="text"
                    name="floating_address"
                    id="floating_address"
                    className={`block py-2.5 px-0 w-full text-sm  bg-transparent border-0 border-b-2  appearance-none focus:outline-none focus:ring-0  peer ${darkMode
                      ? "text-white focus:border-blue-500 border-gray-600"
                      : "text-gray-900 border-gray-300 focus:border-blue-600"
                      }`}
                    placeholder=" "
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    required
                  />
                  <label
                    htmlFor="floating_address"
                    className={`peer-focus:font-medium absolute text-sm   duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 ${darkMode ? "text-gray-400" : "text-gray-500"
                      }`}
                  >
                    Enter Customer Address
                  </label>
                </div>
              </div>

              <button
                type="submit"
                className={`text-white   focus:ring-4 focus:outline-none  font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center ${darkMode
                  ? "focus:ring-blue-800 hover:bg-blue-700 bg-blue-600"
                  : "focus:ring-blue-300 hover:bg-blue-800 bg-blue-700"
                  }`}
              >
                Submit
              </button>
            </form>
          </div>
        )}
      </div>

      <div
        className={`flex flex-col md:flex-row gap-4 ${darkMode ? "bg-[#111827]" : ""
          } p-4 pt-2 md:p-6`}
      >
        {!customer.isLoading && customer.data && (
          <div
            className={`${darkMode
              ? "text-white bg-gray-800 border-[#2b2c37]"
              : " bg-white text-black"
              } rounded-lg border w-full md:w-1/3 shadow-md p-4 md:p-6 h-fit`}
          >
            <div className={`space-y-4 p-4 md:p-6`}>
              <div className="flex items-center space-x-4 mb-4">
                <div>
                  <h2 className="text-2xl md:text-3xl font-semi-bold text-green-700">
                    {customer.data.Name} ({customer.data.Orders || 0} Orders)
                  </h2>
                  <p className="text-xl md:text-2xl text-pink-800">
                    [{customer.data.MemberPoints || 0} Points]
                  </p>
                </div>
              </div>
              <InfoItem
                label="Email"
                value={customer.data.Email}
                icon={<FaEnvelope />}
              />
              <InfoItem
                label="Phone"
                value={customer.data.Mobile}
                icon={<FaPhone />}
              />
              <InfoItem
                label="Address"
                value={customer.data.Address || "N/A"}
                icon={<FaMapMarkerAlt />}
              />
              <InfoItem
                label="Location"
                value={customer.data.City}
                icon={<FaMapPin />}
              />
              <InfoItem
                label="Member Since"
                value={new Date(customer.data.Created).toLocaleDateString()}
                icon={<FaCalendarAlt />}
              />
              {/* <InfoItem label="Complains" value={customer.data?.complains.length.toString() || "0"} icon={<FaExclamationTriangle />} /> */}
              <InfoItem
                label="Confirmed Orders"
                value={customer.data.Orders || "0"}
                icon={<FaCheckCircle />}
              />
              <InfoItem
                label="Cancelled Orders"
                value={customer.data.Orders || "0"}
                icon={<FaTimesCircle />}
              />
              <InfoItem
                label="Loyality Points"
                value={customer.data.MemberPoints || "0"}
                icon={<FaStar />}
              />
              <div className="mt-4 flex flex-col md:flex-row justify-between gap-4">
                <button className="px-4 md:px-6 py-2 md:py-3 bg-white text-gray-800 rounded-lg shadow-md hover:bg-red-600 hover:text-white transition duration-300 ease-in-out transform hover:scale-105">
                  <TrashIcon className="w-5 h-5 inline mr-2" />
                  Delete
                </button>
                <button className="px-4 md:px-6 py-2 md:py-3 bg-indigo-500 text-white rounded-lg shadow-md hover:bg-indigo-600 transition duration-300 ease-in-out transform hover:scale-105">
                  <FilePenIcon className="w-5 h-5 inline mr-2" />
                  Edit
                </button>
              </div>
            </div>
          </div>
        )}

        {!customer.isLoading && customer.data && customer.data.orders && (
          <div
            className={`w-full md:w-2/3 space-y-6 border rounded-lg shadow-sm p-4 md:p-6 ${darkMode ? "bg-gray-800 border-[#2b2c37]" : "bg-white"
              }`}
          >
            <div
              className={`${darkMode
                ? "text-white border-[#2b2c37]"
                : "bg-white text-gray-800"
                } p-4 md:p-6 border rounded-lg`}
            >
              <h3 className="text-lg md:text-xl font-semibold mb-4">
                Current Order
              </h3>
              {customer.data.orders.length > 0 ? (
                <div className="flex flex-col md:flex-row justify-between items-start md:items-center p-4 md:p-6 pt-0 gap-4">
                  <div className="flex items-start md:items-center space-x-4">
                    <div className="flex justify-center text-white rounded-full bg-green-600 w-8 h-8 md:w-10 md:h-10 items-center">
                      <FlameIcon />
                    </div>
                    <div>
                      <div className="flex flex-col md:flex-row items-start md:items-center space-y-2 md:space-y-0 md:space-x-2">
                        <h3 className="font-bold text-base md:text-lg">
                          {customer.data.orders[0].OrderType}
                        </h3>
                        <div
                          className={`h-[24px] md:h-[28px] flex items-center justify-center ${customer.data.orders[0].Status === "Confirmed"
                            ? "bg-green-300"
                            : "bg-orange-400"
                            } rounded-md px-[8px] text-green-800 font-semibold md:text-sm text-xs`}
                        >
                          {customer.data.orders[0].Status}
                        </div>
                      </div>
                      <p className="text-xs md:text-sm text-muted-foreground mt-2 md:mt-0">
                        Order # {customer.data.orders[0].ID} for{" "}
                        <span className="font-semibold">
                          Rs. {customer.data.orders[0].OrderAmount}
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-col md:flex-row w-full md:w-auto justify-between items-start md:items-center gap-2 md:gap-4">
                    <button className="px-3 md:px-4 py-2 bg-red-500 text-white rounded-md w-full md:w-auto hover:bg-red-600"
                      onClick={() => {
                        setSelectedOrderId(customer.data && customer.data.orders[0].ID);
                        setComplaintModalOpen(true);
                      }}
                    >
                      Add Complain
                    </button>
                    <button
                      className="px-3 md:px-4 py-2 bg-white hover:text-white text-gray-800 rounded-md hover:bg-gray-600 w-full md:w-auto"
                      onClick={() => {
                        fetchOrders(
                          customer.data && customer.data.orders[0].ID
                        );
                        setOpenModal(true);
                      }}
                    >
                      View Order
                    </button>
                  </div>
                </div>
              ) : (
                <p>No Current Orders</p>
              )}
            </div>
            <div
              className={`${darkMode ? "text-white bg-gray-800" : "bg-white text-gray-800"
                } p-4 md:p-6 border rounded-lg`}
            >
              <div className="flex justify-between items-center text-lg md:text-xl font-semibold p-4 md:p-6">
                <h3 className="font-bold">Addresses</h3>
                <button
                  onClick={() => {
                    setAddAddressPopup(true);
                  }}
                  className="text-red-600 font-[600] text-[14px] hover:bg-red-600 hover:text-white px-[12px] py-[4px] rounded-full hover:scale-105 ease-in-out duration-100"
                >
                  Add new +
                </button>
              </div>
              <div
                className={`${darkMode ? "border-[#2b2c37]" : ""
                  } space-y-4 p-4 md:p-6 border rounded-lg`}
              >
                {customer.data.otherAddresses.map(
                  (address: any, index: number) => (
                    <div
                      key={index}
                      className="flex flex-col md:flex-row justify-between items-center"
                    >
                      <div className="flex flex-col gap-2">
                        <div className="flex items-center justify-between gap-3">
                          <p className="font-semibold">{address.Address}</p>
                          {index === 0 && (
                            <span className="text-xs bg-blue-200 text-blue-800 px-2 py-1 rounded-md">
                              Primary
                            </span>
                          )}
                        </div>
                        <div className="flex items-center justify-between gap-4">
                          <div
                            className={`px-4 py-2 font-medium text-xs ${darkMode
                              ? "text-white bg-[#111827]"
                              : "text-white bg-[#111827]"
                              } rounded-full`}
                          >
                            {address.Type}
                          </div>
                          <div
                            className={`${darkMode ? "text-white" : "text-gray-800"
                              } font-semibold text-xs`}
                          >
                            City: {address.City}
                          </div>
                          <div
                            className={`${darkMode ? "text-white" : "text-gray-800"
                              } font-semibold text-xs`}
                          >
                            Area: {address.Area}
                          </div>
                          {address.NearestLandmark && (
                            <div
                              className={`${darkMode ? "text-white" : "text-gray-800"
                                } font-semibold text-xs`}
                            >
                              Landmark: {address.NearestLandmark}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="space-x-2 flex items-center gap-2">
                        <button className="text-white bg-red-500 hover:bg-red-800 px-4 py-2 rounded-md">
                          Delete
                        </button>
                        <button className="text-white bg-indigo-600 hover:bg-indigo-800  px-4 py-2 flex gap-2 justify-between items-center rounded-md">
                          <FilePenIcon className="w-5 h-5" />
                          <span>Edit</span>
                        </button>
                        <button
                          className="bg-green-600 text-white hover:bg-green-800  px-4 py-2 rounded-md"
                          onClick={() => {
                            dispatch(SET_SELECTEDHEADER("Pickup"));
                            dispatch(SET_ARROWKEYLEVEL(0));
                            dispatch(SET_ITERATOR(0));
                            dispatch(SET_TOPLEVELCATEGORY(""));
                            dispatch(SET_POPUPSECTION(0));
                            dispatch(SET_POPUPITERATOR(0));
                            dispatch(handleOpen(false));
                            dispatch(SET_FLAVOURS_AND_TOPPINGS_OPEN(false));
                          }}
                        >
                          <span>Place Order</span>
                        </button>
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
            <div
              className={`${darkMode ? " text-white" : "bg-white text-gray-800"
                }`}
            >
              <button
                onClick={() => {
                  setSelectedTab("Orders");
                }}
                className={`${selectedTab === "Orders"
                  ? darkMode
                    ? "border-b-[2px] border-[#595d69] text-slate-100"
                    : "border-b-[2px] border-[#595d69] text-slate-800"
                  : "text-slate-500"
                  } text-xl font-semibold p-6`}
              >
                Order History
              </button>
              <button
                onClick={() => {
                  setSelectedTab("Complain");
                }}
                className={`${selectedTab === "Complain"
                  ? darkMode
                    ? "border-b-[2px] border-[#595d69] text-slate-100"
                    : "border-b-[2px] border-[#595d69] text-slate-800"
                  : "text-slate-500"
                  } text-xl font-semibold p-6`}
              >
                Complains
              </button>
              <div className="p-6">
                {selectedTab === "Orders" &&
                  customer.data &&
                  (customer.data.orders.length > 0 ? (
                    <div className="overflow-x-auto">
                      <table className="min-w-full text-left text-sm md:text-base">
                        <thead>
                          <tr
                            className={`text-center ${darkMode
                              ? "text-white bg-gray-600"
                              : "text-gray-500"
                              } bg-gray-200`}
                          >
                            <th className="p-3">REFERENCE</th>
                            <th className="p-3">ORDER ADDRESS</th>
                            <th className="p-3">STATUS</th>
                            <th className="p-3">DATE</th>
                            <th className="p-3">AMOUNT</th>
                            <th className="p-3">ACTION</th>
                          </tr>
                        </thead>
                        <tbody>
                          {customer.data.orders.map((order: any) => (
                            <tr
                              key={order.ID}
                              className={`border-t cursor-pointer text-center ${darkMode ? "border-[#2b2c37]" : ""
                                }`}
                            >
                              <td className="p-3">#{order.ID}</td>
                              <td className="p-3">{order.City}</td>
                              <td className="p-3">
                                <div className="flex items-center justify-center gap-2 px-2">
                                  <div
                                    className={`w-[12px] h-[12px] rounded-full ml-2 ${order.Status === "Confirmed"
                                      ? "bg-green-500"
                                      : "bg-orange-400"
                                      }`}
                                  ></div>
                                  <div className="flex items-center justify-center gap-1">
                                    <h3>{order.Status}</h3>
                                  </div>
                                </div>
                              </td>
                              <td className="p-3">
                                {new Date(
                                  order.ModifiedDate
                                ).toLocaleDateString()}
                              </td>
                              <td className="text-red-400 p-3">
                                Rs. {order.OrderAmount}/-
                              </td>
                              <td className="p-3">
                                <div className="space-x-2 flex items-center justify-center">
                                  <button
                                    className={`text-white bg-red-500 hover:bg-red-600 rounded-md px-4 py-2`}
                                  >
                                    <span>Complain</span>
                                  </button>
                                  <button
                                    onClick={() => {
                                      fetchOrders(order.ID);
                                      setOpenModal(true);
                                    }}
                                    className={`${darkMode
                                      ? "text-white bg-gray-600"
                                      : "text-gray-800 bg-white"
                                      } hover:bg-gray-800 hover:text-white  rounded-md px-4 py-2`}
                                  >
                                    <span>View Details</span>
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <div>No Orders Found!!</div>
                  ))}
                {selectedTab === "Complain" &&
                  customer.data &&
                  (customer.data.complains.length > 0 ? (
                    <div className="overflow-x-auto">
                      <table className="min-w-full text-left text-sm md:text-base">
                        <thead>
                          <tr
                            className={`text-center ${darkMode
                              ? "text-white bg-gray-600"
                              : "text-gray-500"
                              } bg-gray-200`}
                          >
                            <th className="p-3">REFERENCE</th>
                            <th className="p-3">TITLE</th>
                            <th className="p-3">STATUS</th>
                            <th className="p-3">DATE</th>
                            <th className="p-3">TYPE</th>
                            <th className="p-3">ACTION</th>
                          </tr>
                        </thead>
                        <tbody>
                          {customer.data.complains.map((complain: any) => (
                            <tr
                              key={complain.id}
                              className={`border-t cursor-pointer text-center ${darkMode ? "border-[#2b2c37]" : ""
                                }`}
                            >
                              <td className="p-3">#{complain.id}</td>
                              <td className="p-3">{complain.title}</td>
                              <td className="p-3">
                                <div className="flex items-center justify-center gap-1">
                                  <div
                                    className={`w-[12px] h-[12px] rounded-full ml-2 ${complain.Status === "In Progress"
                                      ? "bg-green-500"
                                      : "bg-orange-400"
                                      }`}
                                  ></div>
                                  <div className="flex items-center justify-center gap-1">
                                    <h3>{complain.Status}</h3>
                                  </div>
                                </div>
                              </td>
                              <td className="py-3">{complain.Date}</td>
                              <td className="text-red-400 py-3">
                                {complain.Type}
                              </td>
                              <td className="p-3">
                                <div className="space-x-2 flex items-center justify-center">
                                  <button
                                    onClick={() => {
                                      fetchComplains(complain.id);
                                      setComplainsModal(true);
                                    }}
                                    className={`${darkMode
                                      ? "text-white bg-gray-600"
                                      : "text-gray-800 bg-white"
                                      }hover:bg-gray-800 hover:text-white rounded-md px-4 py-2`}
                                  >
                                    <span>View Details</span>
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <div>No Complains Found!!</div>
                  ))}
              </div>
            </div>
          </div>
        )}
      </div>

      <Modal
        sx={{
          boxShadow: 1,
          borderRadius: 2,
          p: 2,
          minWidth: 300,
          border: 0,
        }}
        disableAutoFocus={true}
        open={addAddressPopup}
        onClose={() => setAddAddressPopup(false)}
      >
        <div
          className={`bg-[${!darkMode
            ? pos_config?.popup_color
            : pos_config?.popup_color_darkmode
            }] p-4 md:p-8 mt-[50px] md:mt-[100px] rounded-[12px] flex-col items-center justify-between border-none w-[90%] max-w-[450px] mx-auto select-none`}
        >
          <div
            className={`text-[${!darkMode
              ? pos_config?.popup_text_color
              : pos_config?.popup_text_color_darkmode
              }] text-lg md:text-2xl font-[700] text-center`}
          >
            Add New Address
          </div>
          <form
            onSubmit={async (e) => {
              e.preventDefault();

              if (customer.data) {
                const payload = {
                  collection: "customerddress",
                  filter: {
                    id: customer.data.ID,
                  },
                  data: {

                    CustomerID: customer.data.ID,
                    Address: newAddress,
                    City: selectedCity,
                    Area: selectedArea,
                    Type: selectedType,
                    NearestLandmark: selectedNearestLandmark,


                  },
                };


                await NewBaseService.post("/dynamic/create", payload, {}).then(
                  async (res) => {
                    setAddAddressPopup(false);
                    console.log(res);
                    await getCustomers(phone);
                    setNewAddress(undefined);
                  }
                );
              }
            }}
            className="w-full mt-[20px]"
          >
            <div className="relative z-0 w-full mb-5 group">
              <input
                type="text"
                name="address"
                id="address"
                className={`block py-2.5 px-0 w-full text-sm bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 peer ${darkMode
                  ? "text-white focus:border-blue-500 border-gray-600"
                  : "text-gray-900 border-gray-300 focus:border-blue-600"
                  }`}
                placeholder=" "
                value={newAddress}
                onChange={(e) => setNewAddress(e.target.value)}
                required
              />
              <label
                htmlFor="address"
                className={`peer-focus:font-medium absolute text-sm duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 ${darkMode ? "text-gray-400" : "text-gray-500"
                  }`}
              >
                Enter New Address
              </label>
            </div>

            <div className="mb-4">
              <Autocomplete
                options={cities}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Enter your City"
                    variant="standard"
                    InputLabelProps={{
                      className: darkMode
                        ? "text-gray-400 !important"
                        : "text-gray-500 !important",
                    }}
                    InputProps={{
                      ...params.InputProps,
                      className: `block py-2.5 px-0 w-full text-sm bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 ${darkMode
                        ? "text-white border-gray-600 focus:border-blue-500 !important"
                        : "text-gray-900 border-gray-300 focus:border-blue-600 !important"
                        } !important`,
                    }}
                  />
                )}
                value={selectedCity}
                onChange={(event, newValue) => {
                  const selectedCity = newValue || "";
                  setSelectedCity(selectedCity);
                  fetchAreas(selectedCity);
                }}
              />
            </div>

            <div className="mb-4">
              <Autocomplete
                options={areas}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Enter your Area"
                    variant="standard"
                    InputLabelProps={{
                      className: darkMode
                        ? "text-gray-400 !important"
                        : "text-gray-500 !important",
                    }}
                    InputProps={{
                      ...params.InputProps,
                      className: `block py-2.5 px-0 w-full text-sm bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 ${darkMode
                        ? "text-white border-gray-600 focus:border-blue-500 !important"
                        : "text-gray-900 border-gray-300 focus:border-blue-600 !important"
                        } !important`,
                    }}
                  />
                )}
                value={selectedArea}
                onChange={(event, newValue) => {
                  setSelectedArea(newValue || "");
                }}
              />
            </div>

            <div className="mb-4">
              <Autocomplete
                options={addressTypes}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Type of Address"
                    variant="standard"
                    InputLabelProps={{
                      className: darkMode
                        ? "text-gray-400 !important"
                        : "text-gray-500 !important",
                    }}
                    InputProps={{
                      ...params.InputProps,
                      className: `block py-2.5 px-0 w-full text-sm bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 ${darkMode
                        ? "text-white border-gray-600 focus:border-blue-500 !important"
                        : "text-gray-900 border-gray-300 focus:border-blue-600 !important"
                        } !important`,
                    }}
                  />
                )}
                value={selectedType}
                onChange={(event, newValue) => {
                  const type = newValue || "";
                  setSelectedType(type);
                }}
              />
            </div>

            <div className="relative z-0 w-full mb-5 group">
              <input
                type="text"
                name="landmark"
                id="landmark"
                className={`block py-2.5 px-0 w-full text-sm  bg-transparent border-0 border-b-2  appearance-none focus:outline-none focus:ring-0  peer ${darkMode
                  ? "text-white focus:border-blue-500 border-gray-600"
                  : "text-gray-900 border-gray-300 focus:border-blue-600"
                  }`}
                placeholder=" "
                value={selectedNearestLandmark}
                onChange={(e) => setSelectedNearestLandmark(e.target.value)}
              />
              <label
                htmlFor="address"
                className={`peer-focus:font-medium absolute text-sm   duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 ${darkMode ? "text-gray-400" : "text-gray-500"
                  }`}
              >
                Enter Nearest Landmark
              </label>
            </div>
            <div className="flex flex-row justify-around pt-4 md:pt-6">
              <button
                type="submit"
                className={`cursor-pointer w-[140px] md:w-[165px] h-[40px] md:h-[46px] rounded-[62px] flex items-center justify-center bg-[${!darkMode
                  ? pos_config?.popup_secondary_button_color
                  : pos_config?.popup_secondary_button_color_darkmode
                  }] text-[${!darkMode
                    ? pos_config?.popup_secondary_button_text_color
                    : pos_config?.popup_secondary_button_text_color_darkmode
                  }] text-[14px] md:text-[16px] font-[700]`}
              >
                Add
              </button>
            </div>
          </form>
        </div>
      </Modal>
      {/* <Modal
        sx={{
          boxShadow: 1,
          borderRadius: 2,
          p: 2,
          minWidth: 300,
          border: 0,
        }}
        disableAutoFocus={true}
        open={openModal}
        onClose={() => setOpenModal(false)}
      >
        <div
          className={`bg-[${!darkMode
            ? pos_config?.popup_color
            : pos_config?.popup_color_darkmode
            }] p-[20px] md:p-[32px] mt-[100px] rounded-[12px] flex-col items-center justify-between border-none w-[90%] max-w-[450px] mx-auto select-none`}
        >
          {selectedOrderDetails && (
            <div
              className={`text-[${!darkMode
                ? pos_config?.popup_text_color
                : pos_config?.popup_text_color_darkmode
                }] text-[20px] md:text-[24px] font-[700] text-center mb-[12px] md:mb-[16px]`}
            >
              Order Details
            </div>
          )}
          <div>
            <div
              className={`
              ${darkMode ? "border-[#2b2c37]" : "border-[#E8E8E8]"}
              border-[1px] rounded-[12px] md:rounded-[16px] p-[12px] md:p-[16px]`}
            >
              <div>
                <div
                  className={`${darkMode
                    ? `text-[${pos_config?.order_bar_text_color_darkmode}]`
                    : `text-[${pos_config?.order_bar_text_color}]`
                    } font-[600] text-[14px] md:text-[16px]`}
                >
                  Order ID: #{selectedOrderDetails?.ID}
                </div>
                <div
                  className={`flex flex-col gap-[4px] md:gap-[6px] items-start pt-[4px] md:pt-[5px] justify-start h-[100px] max-h-[100px] overflow-auto text-[${darkMode
                    ? `${pos_config?.order_bar_menu_text_color}`
                    : `${pos_config?.order_bar_menu_text_color_darkmode}`
                    }] font-[400] text-[10px] md:text-[11px] leading-[12px] md:leading-[14px]`}
                >
                  <div className="flex justify-between ">
                    <div>Customer Name: </div>
                    <div className="pr-[6px] md:pr-[10px]">
                      {selectedOrderDetails?.CustomerName}
                    </div>
                  </div>
                  <div className="flex justify-between ">
                    <div>Customer Mobile Number: </div>
                    <div className="pr-[6px] md:pr-[10px]">
                      {selectedOrderDetails?.CustomerMobile}
                    </div>
                  </div>
                  <div className="flex justify-between ">
                    <div>Order Type: </div>
                    <div className="pr-[6px] md:pr-[10px]">
                      {selectedOrderDetails?.OrderType}
                    </div>
                  </div>
                  <div className="flex justify-between ">
                    <div>Payment Type: </div>
                    <div className="pr-[6px] md:pr-[10px]">
                      {selectedOrderDetails?.PaymentType}
                    </div>
                  </div>
                </div>
                <div
                  className={`
                  ${darkMode
                      ? `bg-[${pos_config?.order_bar_status_color_darkmode}] text-[${pos_config?.order_bar_text_color_darkmode}] border-[#2b2c37] border-[1px]`
                      : `bg-[${pos_config?.order_bar_status_color}] text-[${pos_config?.order_bar_text_color}]`
                    }
                  flex items-center w-[150px] md:w-[170px] py-[5px] md:py-[6px] px-[14px] md:px-[16px] rounded-[40px] md:rounded-[49px] font-[700] text-[10px] md:text-[11px]`}
                >
                  <div className="flex items-center justify-center gap-1">
                    Status:
                    <h3>
                      {selectedOrderDetails && selectedOrderDetails.Status}
                    </h3>
                  </div>
                  <div
                    className={`w-[10px] md:w-[12px] h-[10px] md:h-[12px] rounded-full ml-2 ${selectedOrderDetails &&
                      selectedOrderDetails.Status === "Confirmed"
                      ? "bg-green-500"
                      : "bg-orange-400"
                      }`}
                  ></div>
                </div>
                <div className="flex items-center py-[2px] md:py-[4px]">
                  <div
                    className={`${darkMode
                      ? `text-[${pos_config?.order_bar_text_color_darkmode}]`
                      : `text-[${pos_config?.order_bar_text_color}]`
                      } font-[500] text-[10px] md:text-[11px]`}
                  >
                    Total Bill:
                  </div>
                  <div className="font-[500] text-[10px] md:text-[11px] text-[#EF233C]">
                    Rs.{" "}
                    {selectedOrderDetails && selectedOrderDetails.OrderAmount}
                  </div>
                  <div className="font-[500] text-[5px] md:text-[6px]">
                    {" "}
                    (Incl. GST)
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-end mt-4">
            <button
              onClick={() => setOpenModal(false)}
              className={`cursor-pointer px-3 md:px-4 py-1.5 md:py-2 rounded-[50px] md:rounded-[62px] bg-[${!darkMode
                ? pos_config?.popup_secondary_button_color
                : pos_config?.popup_secondary_button_color_darkmode
                }] text-[${!darkMode
                  ? pos_config?.popup_secondary_button_text_color
                  : pos_config?.popup_secondary_button_text_color_darkmode
                }] text-[14px] md:text-[16px] font-[700]`}
            >
              Close
            </button>
          </div>
        </div>
      </Modal> */}
      <Modal
        sx={{
          boxShadow: 1,
          borderRadius: 2,
          p: 2,
          minWidth: 300,
          border: 0,
        }}
        disableAutoFocus={true}
        open={openComplainsModal}
        onClose={() => setComplainsModal(false)}
      >
        <div
          className={`bg-[${!darkMode
            ? pos_config?.popup_color
            : pos_config?.popup_color_darkmode
            }] p-[20px] md:p-[32px] mt-[100px] rounded-[12px] flex-col items-center justify-between border-none w-[90%] max-w-[450px] mx-auto select-none`}
        >
          {selectedComplainDetails && (
            <div
              className={`text-[${!darkMode
                ? pos_config?.popup_text_color
                : pos_config?.popup_text_color_darkmode
                }] text-[20px] md:text-[24px] font-[700] text-center mb-[12px] md:mb-[16px]`}
            >
              Complain Details
            </div>
          )}
          <div>
            <div
              className={`
              ${darkMode ? "border-[#2b2c37]" : "border-[#E8E8E8]"}
              border-[1px] rounded-[12px] md:rounded-[16px] p-[12px] md:p-[16px]`}
            >
              <div>
                <div
                  className={`${darkMode
                    ? `text-[${pos_config?.order_bar_text_color_darkmode}]`
                    : `text-[${pos_config?.order_bar_text_color}]`
                    } font-[600] text-[14px] md:text-[16px]`}
                >
                  Complain ID: #{selectedComplainDetails?.id}
                </div>
                <div
                  className={`flex flex-col gap-[4px] md:gap-[6px] items-start pt-[4px] md:pt-[5px] justify-start h-[100px] max-h-[100px] overflow-auto text-[${darkMode
                    ? `${pos_config?.order_bar_menu_text_color}`
                    : `${pos_config?.order_bar_menu_text_color_darkmode}`
                    }] font-[400] text-[10px] md:text-[11px] leading-[12px] md:leading-[14px]`}
                >
                  <div className="flex justify-between ">
                    <div>Customer Name: </div>
                    <div className="pr-[6px] md:pr-[10px]">
                      {selectedComplainDetails?.Name}
                    </div>
                  </div>
                  <div className="flex justify-between ">
                    <div>Customer Mobile Number: </div>
                    <div className="pr-[6px] md:pr-[10px]">
                      {selectedComplainDetails?.Mobile}
                    </div>
                  </div>
                  <div className="flex justify-between ">
                    <div>Outlet: </div>
                    <div className="pr-[6px] md:pr-[10px]">
                      {selectedComplainDetails?.outlet}
                    </div>
                  </div>
                  <div className="flex justify-between ">
                    <div>Complain Type: </div>
                    <div className="pr-[6px] md:pr-[10px]">
                      {selectedComplainDetails?.Type}
                    </div>
                  </div>
                  <div className="flex justify-between ">
                    <div>Description: </div>
                    <div className="pr-[6px] md:pr-[10px]">
                      {selectedComplainDetails?.Complain}
                    </div>
                  </div>
                </div>
                <div
                  className={`
                  ${darkMode
                      ? `bg-[${pos_config?.order_bar_status_color_darkmode}] text-[${pos_config?.order_bar_text_color_darkmode}] border-[#2b2c37] border-[1px]`
                      : `bg-[${pos_config?.order_bar_status_color}] text-[${pos_config?.order_bar_text_color}]`
                    }
                  flex items-center w-[150px] md:w-[170px] py-[5px] md:py-[6px] px-[14px] md:px-[16px] rounded-[40px] md:rounded-[49px] font-[700] text-[10px] md:text-[11px]`}
                >
                  <div className="flex items-center justify-center gap-1">
                    Status:
                    <h3>
                      {selectedComplainDetails &&
                        selectedComplainDetails.Status}
                    </h3>
                  </div>
                  <div
                    className={`w-[10px] md:w-[12px] h-[10px] md:h-[12px] rounded-full ml-2 ${selectedComplainDetails &&
                      selectedComplainDetails.Status === "in Progress"
                      ? "bg-green-500"
                      : "bg-orange-400"
                      }`}
                  ></div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-end mt-4">
            <button
              onClick={() => setComplainsModal(false)}
              className={`cursor-pointer px-3 md:px-4 py-1.5 md:py-2 rounded-[50px] md:rounded-[62px] bg-[${!darkMode
                ? pos_config?.popup_secondary_button_color
                : pos_config?.popup_secondary_button_color_darkmode
                }] text-[${!darkMode
                  ? pos_config?.popup_secondary_button_text_color
                  : pos_config?.popup_secondary_button_text_color_darkmode
                }] text-[14px] md:text-[16px] font-[700]`}
            >
              Close
            </button>
          </div>
        </div>
      </Modal>
      <ComplaintModal
        open={complaintModalOpen}
        onClose={() => setComplaintModalOpen(false)}
        orderId={selectedOrderId}
        orderDetails={customer.data?.orders.find(order => order.ID === selectedOrderId)}
      />
      <OrderDetailsModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        orderDetails={selectedOrderDetails}
      />
    </div>
  );
};

export default Customers;
