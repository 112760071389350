import React, { useState, useCallback, useEffect } from 'react';
import Modal from '@mui/material/Modal';
import { useSelector } from 'react-redux';
import { AiFillStar } from 'react-icons/ai';
import { IoClose } from 'react-icons/io5';
import { IoSend } from 'react-icons/io5';
import emoji1 from '../../../../assets/emoji1.png';
import emoji2 from '../../../../assets/emoji2.png';
import emoji3 from '../../../../assets/emoji3.png';
import emoji4 from '../../../../assets/emoji4.png';
import emoji5 from '../../../../assets/emoji5.png';
import { RootState } from '../../../..';
import { createNewBaseService } from '../../../../assets/BaseService';

interface FeedbackModalProps {
  open: boolean;
  onClose: () => void;
  orderId: string;
  orderDetails: any;
}

interface Answer {
  questionId: number;
  question: string;
  answer: string;
  reason?: string;
}

const FeedbackModal: React.FC<FeedbackModalProps> = ({ open, onClose, orderId, orderDetails }) => {
  const [data, setData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [answers, setAnswers] = useState<Answer[]>([]);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [showThankYou, setShowThankYou] = useState(false);
  const [hoveringStars, setHoveringStars] = useState<{ [key: number]: number }>({});
  const [selectedReasons, setSelectedReasons] = useState<{ [key: number]: string }>({});

  const darkMode = useSelector((state: RootState) => state.leftHeader.darkMode);

  const pos_config = useSelector((state:RootState)=>state.leftHeader.pos_config)

  const NewBaseService = createNewBaseService(pos_config)

  useEffect(() => {
    if (orderDetails) {
      setName(orderDetails.CustomerName || '');
      setEmail(orderDetails.Email || '');
      setPhone(orderDetails.CustomerMobile || '');
    }
  }, [orderDetails]);

  const fetchFeedbackConfig = useCallback(async () => {
    try {
      const res = await NewBaseService.post("/dynamic/get", { collection: "ratings_app" });
      setData(res.data.data[0]);
      setIsLoading(false);
    } catch (err) {
      console.error("Error fetching feedback config:", err);
    }
  }, []);

  useEffect(() => {
    if (open) {
      fetchFeedbackConfig();
    }
  }, [open, fetchFeedbackConfig]);

  const handleAnswer = (questionIndex: number, answer: string) => {
    setAnswers(prev => {
      const newAnswers = [...prev];
      const existingAnswerIndex = newAnswers.findIndex(a => a.questionId === questionIndex);

      const answerObj = {
        questionId: questionIndex,
        question: data.ratings_appquestions[questionIndex].title,
        answer: answer,
        reason: selectedReasons[questionIndex]
      };

      if (existingAnswerIndex >= 0) {
        newAnswers[existingAnswerIndex] = answerObj;
      } else {
        newAnswers.push(answerObj);
      }

      return newAnswers;
    });
  };

  const handleReasonSelect = (questionIndex: number, reason: string) => {
    setSelectedReasons(prev => ({
      ...prev,
      [questionIndex]: reason
    }));

    setAnswers(prev => {
      const newAnswers = [...prev];
      const existingAnswer = newAnswers.find(a => a.questionId === questionIndex);
      if (existingAnswer) {
        existingAnswer.reason = reason;
      }
      return newAnswers;
    });
  };

  const renderRating = (questionIndex: number, type: string) => {
    if (type === 'emoji') {
      const emojis = [
        { icon: emoji1, label: 'Very Bad' },
        { icon: emoji2, label: 'Bad' },
        { icon: emoji3, label: 'Average' },
        { icon: emoji4, label: 'Good' },
        { icon: emoji5, label: 'Excellent' }
      ];

      return (
        <div className="flex justify-between w-full mt-2">
          {emojis.map((emoji, i) => (
            <div
              key={i}
              onClick={() => handleAnswer(questionIndex, emoji.label)}
              className={`flex flex-col items-center cursor-pointer transition-all duration-300 hover:scale-110 p-2
                ${answers.find(a => a.questionId === questionIndex)?.answer === emoji.label
                  ? 'scale-110 bg-blue-50 dark:bg-blue-900 rounded-lg shadow-md'
                  : ''}`}
            >
              <img src={emoji.icon} alt={emoji.label} className="w-14 h-14" />
              <span className={`mt-2 text-sm font-medium ${darkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                {emoji.label}
              </span>
            </div>
          ))}
        </div>
      );
    }

    return (
      <div className="flex justify-between w-full mt-2">
        {[1, 2, 3, 4, 5].map((star) => (
          <div
            key={star}
            onClick={() => handleAnswer(questionIndex, `${star} Star`)}
            onMouseEnter={() => setHoveringStars({ ...hoveringStars, [questionIndex]: star })}
            onMouseLeave={() => setHoveringStars({ ...hoveringStars, [questionIndex]: 0 })}
            className="flex flex-col items-center cursor-pointer transition-all duration-300 hover:scale-110"
          >
            <AiFillStar
              className={`text-4xl transition-colors ${(hoveringStars[questionIndex] >= star ||
                  parseInt(answers.find(a => a.questionId === questionIndex)?.answer?.split(' ')[0] || '0') >= star)
                  ? 'text-yellow-400'
                  : 'text-gray-300'
                }`}
            />
            <span className={`mt-2 text-sm font-medium ${darkMode ? 'text-gray-300' : 'text-gray-600'}`}>
              {star === 1 ? 'Poor' : star === 2 ? 'Fair' : star === 3 ? 'Good' : star === 4 ? 'Very Good' : 'Excellent'}
            </span>
          </div>
        ))}
      </div>
    );
  };

  const handleSubmit = async () => {
    if (!data?.ratings_appquestions || answers.length !== data.ratings_appquestions.length) {
      alert('Please answer all questions');
      return;
    }

    try {
      await NewBaseService.post("/dynamic/create", {
        collection: "ratings",
        data: {
          code:orderId,
          answers,
          name,
          email,
          number: phone,
          createdAt: new Date().toISOString()
        }
      });
      setShowThankYou(true);
      setTimeout(() => {
        onClose();
        resetForm();
      }, 3000);
    } catch (error) {
      console.error("Error submitting feedback:", error);
    }
  };

  const resetForm = () => {
    setAnswers([]);
    setName(orderDetails?.CustomerName || '');
    setEmail(orderDetails?.Email || '');
    setPhone(orderDetails?.CustomerMobile || '');
    setShowThankYou(false);
    setSelectedReasons({});
  };

  if (isLoading) {
    return (
      <Modal open={open} onClose={onClose}>
        <div className={`${darkMode ? 'bg-gray-800' : 'bg-white'} rounded-2xl shadow-2xl p-6 
          absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[95%] max-w-5xl`}>
          <div className="flex justify-center items-center h-32">
            <div className="animate-spin rounded-full h-10 w-10 border-4 border-blue-500 border-t-transparent"></div>
          </div>
        </div>
      </Modal>
    );
  }

  return (
    <Modal open={open} onClose={onClose}>
      <div className={`${darkMode ? 'bg-gray-800 text-white' : 'bg-white text-gray-800'} 
        rounded-2xl shadow-2xl absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 
        w-[95%] max-w-5xl max-h-[92vh] overflow-y-auto`}>

        <div className="sticky top-0 z-10 backdrop-blur-md bg-opacity-90 p-4 border-b border-gray-200 dark:border-gray-700">
          <div className="flex justify-between items-center">
            <div className="flex items-center gap-4">
              <h2 className="text-2xl font-bold tracking-tight">Share Your Experience</h2>
              <span className="px-3 py-1 bg-blue-100 dark:bg-blue-900 text-blue-800 dark:text-blue-200 rounded-full text-sm font-medium">
                #{orderDetails?.code}
              </span>
            </div>
            <div className="flex items-center gap-2">
              <button
                onClick={handleSubmit}
                className="p-2 rounded-full hover:bg-gray-200 dark:hover:bg-gray-700 transition-all text-blue-500 hover:text-blue-600"
              >
                <IoSend size={24} />
              </button>
              <button
                onClick={onClose}
                className="p-2 rounded-full hover:bg-gray-200 dark:hover:bg-gray-700 transition-all"
              >
                <IoClose size={24} />
              </button>
            </div>
          </div>
        </div>

        <div className="p-6">
          {!showThankYou ? (
            <div className="space-y-6">
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                {data?.ratings_appquestions.map((question: any, index: number) => (
                  <div key={index}
                    className={`p-5 rounded-xl transition-all duration-300 hover:shadow-lg
                    ${darkMode ? 'bg-gray-700 hover:bg-gray-650' : 'bg-gray-50 hover:bg-gray-100'}`}>
                    <h3 className="text-xl font-semibold mb-4">{question.question}</h3>
                    {renderRating(index, question.type)}

                    {(answers.find(a => a.questionId === index)?.answer?.includes('Bad') ||
                      answers.find(a => a.questionId === index)?.answer?.match(/[12] Star/)) &&
                      question.reasons && (
                        <div className="mt-4 animate-fadeIn">
                          <p className="font-medium mb-3 text-sm text-gray-600 dark:text-gray-300">
                            What could we improve?
                          </p>
                          <div className="grid grid-cols-2 gap-2">
                            {question.reasons.map((reason: string, i: number) => (
                              <button
                                key={i}
                                onClick={() => handleReasonSelect(index, reason)}
                                className={`p-3 rounded-lg text-sm font-medium transition-all
                                ${selectedReasons[index] === reason
                                    ? 'bg-blue-500 text-white transform scale-105'
                                    : `${darkMode ? 'bg-gray-600 hover:bg-gray-500' : 'bg-white hover:bg-gray-100'}`
                                  } hover:shadow-md`}
                              >
                                {reason}
                              </button>
                            ))}
                          </div>
                        </div>
                      )}
                  </div>
                ))}
              </div>

              <div className={`p-5 rounded-xl ${darkMode ? 'bg-gray-700' : 'bg-gray-50'}`}>
                <h3 className="text-xl font-semibold mb-4">Contact Information</h3>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                  {[
                    { label: 'Name', value: name, setter: setName, readonly: !!orderDetails?.CustomerName },
                    { label: 'Email', value: email, setter: setEmail, readonly: !!orderDetails?.Email },
                    { label: 'Phone', value: phone, setter: setPhone, readonly: !!orderDetails?.CustomerMobile }
                  ].map((field, i) => (
                    <div key={i} className="relative">
                      <label className="block text-sm font-medium mb-2">{field.label}</label>
                      <input
                        type={field.label === 'Email' ? 'email' : 'text'}
                        value={field.value}
                        onChange={(e) => field.setter(e.target.value)}
                        readOnly={field.readonly}
                        className={`w-full p-3 rounded-lg text-sm transition-all focus:ring-2 focus:ring-blue-500
                          ${darkMode ? 'bg-gray-600' : 'bg-white'} 
                          ${field.readonly ? 'opacity-75 cursor-not-allowed' : 'hover:bg-gray-50 dark:hover:bg-gray-550'}`}
                      />
                    </div>
                  ))}
                </div>
              </div>

              <button
                onClick={handleSubmit}
                className="w-full py-4 bg-blue-500 text-white rounded-xl font-semibold text-lg
                transition-all duration-300 hover:bg-blue-600 hover:shadow-xl transform hover:scale-[1.02]"
              >
                Submit Feedback
              </button>
            </div>
          ) : (
            <div className="text-center py-12">
              <div className="w-24 h-24 mx-auto mb-6 rounded-full bg-green-100 flex items-center justify-center">
                <svg className="w-14 h-14 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                </svg>
              </div>
              <h2 className="text-3xl font-bold mb-3">Thank You!</h2>
              <p className="text-lg text-gray-600 dark:text-gray-300">
                Your feedback helps us improve our service.
              </p>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default FeedbackModal;

