import React, { useEffect } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Box from "@mui/material/Box";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../..";
import { SET_FILTERED_ORDER, SET_VIEW_ORDER } from "../../../Features/cart";
import { IoMdArrowDropdown } from "react-icons/io";
import { makeStyles } from "@mui/material";

const SelectOrderFilter = () => {
  const cart = useSelector((state: RootState) => state.cart);
  const dispatch = useDispatch();

  const darkMode = useSelector((state: RootState) => state.leftHeader.darkMode);

  const handleChange = (event: SelectChangeEvent) => {
    dispatch(SET_FILTERED_ORDER(event.target.value));
  };

  const handleViewChange = (event: SelectChangeEvent) => {
    dispatch(SET_VIEW_ORDER(event.target.value));
  };

  
return (
  <div className="flex items-center gap-3">
    <FormControl size="small" className="min-w-[130px]">
      <Select
        value={cart.filteredOrder}
        onChange={handleChange}
        displayEmpty
        className={`h-10 ${darkMode ? 'bg-gray-800 text-white' : 'bg-white'}`}
      >
        <MenuItem value="None">All Orders</MenuItem>
        <MenuItem value="hold">On Hold</MenuItem>
        <MenuItem value="Dispatched">Dispatched</MenuItem>
        <MenuItem value="Suspended">Suspended</MenuItem>
      </Select>
    </FormControl>

    <FormControl size="small" className="min-w-[120px]">
      <Select
        value={cart.viewOrder}
        onChange={handleViewChange}
        className={`h-10 ${darkMode ? 'bg-gray-800 text-white' : 'bg-white'}`}
      >
        <MenuItem value="grid">Grid View</MenuItem>
        <MenuItem value="tabular">Table View</MenuItem>
      </Select>
    </FormControl>
  </div>
);


};

export default SelectOrderFilter;
