import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  SET_CURRENT_TABLE,
  SET_SELECTED_ORDER,
  SET_TABLE_NAME,
  SET_CART_ORDER,
  SET_SHOW_CUSTOMER_POPUP,
  SET_FOCUS_ON_CUSTOMER_NAME_POPUP,
  handleOpen,
  SET_CREDITOR_NAME,
  SET_CASH_PAID,
  SET_CARD_PAID,
  SET_CREDIT_PAID,
  SET_PAYMENT_REMARKS,
} from "../../../Features/cart";
import { INITIALIZE_PRODUCT_CONSUMPTION_DETAIL } from "../../../Features/OrderConsumption";
import { BaseService, createNewBaseService } from "../../../assets/BaseService";
import { Option, Orderdaum, Root2 } from "../../../Interfaces/IOrderData";
import { CartOrder, AddOn, Value } from "../../../Interfaces/ICartOrder";
import {
  SET_SELECTEDHEADER,
  SET_VISUAL_HEADER,
} from "../../../Features/rightBarHeader";
import { RootState } from "../../..";
import Table from "./Table";
import { SET_CUSTOMER_DATA } from "../../../Features/customer";
import {
  SET_ARROWKEYLEVEL,
  SET_FLAVOURS_AND_TOPPINGS_OPEN,
  SET_ITERATOR,
  SET_POPUPITERATOR,
  SET_POPUPSECTION,
  SET_TOPLEVELCATEGORY,
} from "../../../Features/ItemsStates";

interface TableListProps {
  section: string | null;
}

const TablesList: React.FC<TableListProps> = ({ section }) => {
  const dispatch = useDispatch();
  const [dataFromAPI, setDataFromAPI] = useState<any[]>([]);
  const [active, setActive] = useState<number | null>(null);
  const [selectedTableIndex, setSelectedTableIndex] = useState<number | null>(
    null
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [tablesWithHoldOrders, setTablesWithHoldOrders] = useState<Set<string>>(
    new Set()
  );
  //@ts-ignore
  const darkMode = useSelector((state: RootState) => state.leftHeader.darkMode);
  const pos_config = useSelector(
    (state: RootState) => state.leftHeader.pos_config
  );
  const NewBaseService = createNewBaseService(pos_config);

  const leftHeader = useSelector((state: RootState) => state.leftHeader);

  const fetchTables = useCallback(async () => {
    setIsLoading(true);
    try {
      const tableResponse = await NewBaseService.post("/dynamic/get", {
        collection: "tables",
      });
      const tables = tableResponse.data.data;
      setDataFromAPI(tables);
    } catch (error) {
      console.error("Error in Rendering Tables:", error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const fetchHoldOrders = useCallback(async () => {
    try {
      const orderResponse = await NewBaseService.get(
        "dynamic/query/get-all-orders-with-details?Status=hold",
        { params: { collection: "orders" } }
      );
      const holdOrders = orderResponse.data.data;
      const holdTables = new Set<string>(
        holdOrders.map((order: any) => order.TableName)
      );
      setTablesWithHoldOrders(holdTables);
    } catch (error) {
      console.error("Error fetching hold orders:", error);
    }
  }, []);

  useEffect(() => {
    fetchTables();
    fetchHoldOrders();
  }, [fetchTables, fetchHoldOrders, leftHeader.isDineIn]);

  const filteredTables = useMemo(
    () =>
      section
        ? dataFromAPI.filter((table) => table.section === section)
        : dataFromAPI,
    [section, dataFromAPI]
  );

  const fetchOrdersForTable = async (tableName: string) => {
    try {
      const orderResponse = await NewBaseService.get(
        "dynamic/query/get-all-orders-with-details?Status=hold",
        { params: { collection: "orders" } }
      );
      const orders = orderResponse.data.data;
      return orders.filter((order: any) => order?.TableName === tableName);
    } catch (orderError) {
      console.error("Error Fetching Orders:", orderError);
      return [];
    }
  };

  const convertOrderDetailsToCartOrder = (orderDetails: Root2): CartOrder => {
    if (!orderDetails.orderdata || !Array.isArray(orderDetails.orderdata)) {
      return [];
    }
    return orderDetails.orderdata.map((orderItem: Orderdaum) => {
      const value: Value[] =
        orderItem.options?.map((option: Option) => ({
          name: option.OptionName,
          properties: [
            {
              ID: option.OptionID,
              Name: option.OptionGroupName,
              Price: parseFloat(option.Price),
              OriginalPrice: parseFloat(option.Price),
              Discount: 0,
              IsActive: true,
            },
          ],
          price: parseFloat(option.Price),
          isMultiple: false,
        })) || [];

      const addOns: AddOn[] =
        orderItem.options?.map((option: Option) => ({
          name: option.OptionName,
          price: parseFloat(option.Price),
          quantity: parseInt(option.Quantity),
          objGroupName: option.OptionGroupName,
          optionID: option.OptionID,
        })) || [];

      return {
        itemName: orderItem.ItemName,
        value,
        total: parseFloat(orderItem.Price),
        itemPrice: parseFloat(orderItem.Price),
        addOns,
        batchNumber: orderItem.batchNumber,
        quantity: parseInt(orderItem.Quantity),
        categoryName: orderItem.Category,
        MinimumDelivery: orderItem.minimumDelivery,
        sizeID: orderItem.SizeID,
        itemID: orderItem.ItemID,
        orderTotal: parseFloat(orderItem.Price),
        index: 0,
        itemStatus: orderItem.Status,
        isComingFromAPI: true,
      };
    });
  };

  const handleArrowKeys = useCallback(
    (event: KeyboardEvent) => {
      const navigate = (step: number) => {
        setSelectedTableIndex((prevIndex) =>
          prevIndex === null
            ? 0
            : (prevIndex + step + filteredTables.length) % filteredTables.length
        );
      };

      switch (event.key) {
        case "ArrowUp":
        case "ArrowLeft":
          navigate(-1);
          break;
        case "ArrowDown":
        case "ArrowRight":
          navigate(1);
          break;
        case "Enter":
          if (selectedTableIndex !== null) {
            handleTableClick(
              filteredTables[selectedTableIndex],
              selectedTableIndex
            );
            // dispatch(SET_SHOW_CUSTOMER_POPUP(true))
            // dispatch(SET_SELECTEDHEADER("Pickup"));
          }
          break;
        default:
          break;
      }
    },
    [filteredTables, selectedTableIndex, dispatch]
  );

  useEffect(() => {
    window.addEventListener("keydown", handleArrowKeys);
    return () => {
      window.removeEventListener("keydown", handleArrowKeys);
    };
  }, [handleArrowKeys]);

  const handleTableClick = async (table: any, index: number) => {

    const orders = await fetchOrdersForTable(table.name);
    const currentOrder = orders && orders[0] ? orders[0] : null;
    dispatch(SET_FOCUS_ON_CUSTOMER_NAME_POPUP(true));

    if (currentOrder && currentOrder.POSStatus === "hold") {
      dispatch(SET_SELECTED_ORDER(currentOrder));
      const convertedOrder = convertOrderDetailsToCartOrder(currentOrder);

      dispatch(
        SET_CUSTOMER_DATA({
          Name: currentOrder.CustomerName,
          Mobile: currentOrder.CustomerMobile || "",
          Email: currentOrder.Email || "",
          ID: currentOrder.CustomerID,
          IsActive: "",
          Address: "",
          City: currentOrder.City,
          MemberPoints: "",
          customerRating: 0,
          otherAddresses: [],
          orders: [],
          complains: [],
          Created: "",
          updatedAt: "",
          Orders: "",
          __v: 0,
        })
      );
      dispatch(SET_CART_ORDER(convertedOrder));
    } else {
      dispatch(SET_SELECTED_ORDER(null));
      dispatch(INITIALIZE_PRODUCT_CONSUMPTION_DETAIL());
      dispatch(SET_CURRENT_TABLE(index + 1));
      setActive((prevActive) => (prevActive === index + 1 ? null : index + 1));
      dispatch(SET_CUSTOMER_DATA(undefined));
      dispatch(SET_SHOW_CUSTOMER_POPUP(true));
      dispatch(SET_CASH_PAID(0))
      dispatch(SET_CARD_PAID(0))
      dispatch(SET_CREDIT_PAID(0))
      dispatch(SET_CREDITOR_NAME(""))
      dispatch(SET_PAYMENT_REMARKS(""))
    }
    dispatch(SET_TABLE_NAME(table.name));
    setSelectedTableIndex(index);
    dispatch(SET_SELECTEDHEADER("Pickup"));
    dispatch(SET_VISUAL_HEADER("Dine-in"));
    dispatch(SET_ARROWKEYLEVEL(0));
    dispatch(SET_ITERATOR(0));
    dispatch(SET_TOPLEVELCATEGORY(""));
    dispatch(SET_POPUPSECTION(0));
    dispatch(SET_POPUPITERATOR(0));
    dispatch(handleOpen(false));
    dispatch(SET_FLAVOURS_AND_TOPPINGS_OPEN(false));
  };

  return (
    <div className="grid sm:grid-cols-4 grid-cols-2 md:grid-cols-8 gap-[12px]">
      {isLoading ? (
        <div
          className={`inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite] ${darkMode ? "text-white" : "text-black"
            }`}
        >
          <span
            className={`!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]`}
          >
            Loading...
          </span>
        </div>
      ) : (
        filteredTables.map((table: any, index: number) => (
          <div key={index} onClick={() => handleTableClick(table, index)}>
            <Table
              index={index + 1}
              tableName={table.name}
              active={active}
              status={table.status}
              orderStatus={tablesWithHoldOrders.has(table.name) ? "hold" : null}
              isSelected={index === selectedTableIndex}
              seating={table.seating}
            />
          </div>
        ))
      )}
    </div>
  );
};

export default TablesList;
