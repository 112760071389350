import axios from "axios";
import isURL from "validator/lib/isURL";


interface IResponse<T> {
  status: number;
  message: string;
  data: T;
}

export const BaseService = axios.create({
  baseURL: "https://api.megnus.app/dev",
});

export const NewBaseService = axios.create({
  baseURL: "https://bwapi.megnus.app/dev",
});

export function createNewBaseService(pos_config: any) {
  const baseURL = pos_config.baseurl || "https://bwapi.megnus.app/dev";
  const instance = axios.create({ baseURL });

  instance.interceptors.request.use((config) => {
    const accessToken = localStorage.getItem("access_token");
    const branchId = localStorage.getItem("branch_id");
    if (config.headers && accessToken) {
      config.headers["Authorization"] = `Bearer ${accessToken}`;
    }
    if (branchId && !config.url?.includes('/update')) {
      const separator = config.url?.includes('?') ? '&' : '?';
      config.url = `${config.url}${separator}OutletID=${branchId}`;
    }

    return config;
  }, (error) => {
    return Promise.reject(error);
  });

  instance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const { response, request } = error;
      if (!!response === false) return Promise.reject(error);
      const statusCode = parseInt(response.data.status || "500");
      const message = response.data.message;
      const requestPathname = isURL(request.responseURL, { require_tld: false }) ? new URL(request.responseURL).pathname : null;
      if (
        statusCode === 401 &&
        requestPathname !== null &&
        requestPathname !== "/dev/auth/refresh"
      ) {
        try {
          const accessToken = localStorage.getItem("access_token");

          const refreshResponse = await BaseService.post(`/auth/refresh`, null, {
            headers: { Authorization: `Bearer ${accessToken}` },
          });
          const refreshResponseData: IResponse<{ token: string }> =
            refreshResponse.data;
          const newAccessToken = refreshResponseData.data.token;

          localStorage.setItem("access_token", newAccessToken);

          // Retry the original request with the new token
          error.config.headers["Authorization"] = `Bearer ${newAccessToken}`;
          return instance.request(error.config);
        } catch (error) {
          return Promise.reject(error);
        }
      } else if (statusCode === 401) {
        // logout user
        localStorage.removeItem("access_token");
        return Promise.reject(error);
      }
      return Promise.reject(error);
    }

  )

  return instance;
}

BaseService.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem("access_token");

    if (config.headers) {
      if (accessToken)
        config.headers["Authorization"] = `Bearer ${accessToken}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

BaseService.interceptors.response.use(
  (response) => response,
  async (error) => {
    const { response, request } = error;
    if (!!response === false) return Promise.reject(error);

    const statusCode = parseInt(response.data.status || "500");
    const message = response.data.message;
    const requestPathname = isURL(request.responseURL, { require_tld: false })
      ? new URL(request.responseURL).pathname
      : null;

    if (
      statusCode === 401 &&
      requestPathname !== null &&
      requestPathname !== "/dev/auth/refresh"
    ) {
      try {
        const accessToken = localStorage.getItem("access_token");

        const refreshResponse = await BaseService.post(`/auth/refresh`, null, {
          headers: { Authorization: `Bearer ${accessToken}` },
        });
        const refreshResponseData: IResponse<{ token: string }> =
          refreshResponse.data;
        const newAccessToken = refreshResponseData.data.token;

        localStorage.setItem("access_token", newAccessToken);

        // Retry the original request with the new token
        error.config.headers["Authorization"] = `Bearer ${newAccessToken}`;
        return BaseService.request(error.config);
      } catch (error) {
        return Promise.reject(error);
      }
    } else if (statusCode === 401) {
      // logout user
      localStorage.removeItem("access_token");
      return Promise.reject(error);
    }

    return Promise.reject(error);
  }
);

// BaseService.post('/auth/signin', { userName: '', password: '' });
// BaseService.post('/auth/signout?session=current');
