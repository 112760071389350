import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  SET_ISCHECKOUT,
  SET_PRINTERPOPUP,
} from "../../Features/rightBarHeader";
import Invoice from "./Invoice";
import {
  INITIALIZE_ORDER_DATA,
  SET_CARD_PAID,
  SET_CASH_PAID,
  SET_CREDIT_PAID,
  SET_CURRENT_TABLE,
  SET_PAYMENT_REMARKS,
  SET_SELECTED_ORDER,
} from "../../Features/cart";
import "./print.css";
import { RootState } from "../..";
import axios from "axios";
import { SET_CUSTOMER_DATA } from "../../Features/customer";
import { INITIALIZE_PRODUCT_CONSUMPTION_DETAIL } from "../../Features/OrderConsumption";

function PrintInvoice() {
  const dispatch = useDispatch();
  const rightBarHeader = useSelector((state: RootState) => state.header);

  const fbrIntegration = async () => {
    let data = {
      InvoiceNumber: "",
      POSID: 987155,
      USIN: "USIN0",
      DateTime: "2020-01-01 12:00:00",
      BuyerNTN: "1234567-8",
      BuyerCNIC: "12345-1234567-8",
      BuyerName: "Buyer Name",
      BuyerPhoneNumber: "0000-0000000",
      items: [
        {
          ItemCode: "IT_1011",
          ItemName: "Test Item",
          Quantity: 1,
          PCTCode: "11001010",
          TaxRate: 0,
          SaleValue: 0,
          TotalAmount: 0,
          TaxCharged: 0,
          Discount: 0,
          FurtherTax: 0,
          InvoiceType: 1,
          RefUSIN: null,
        },
      ],
      TotalBillAmount: 1,
      TotalQuantity: 1,
      TotalSaleValue: 1,
      TotalTaxCharged: 1,
      PaymentMode: 2,
      InvoiceType: 1,
    };

    let config = {
      method: "post",
      maxBodyLength: Infinity,

      url: "https://esp.fbr.gov.pk:8244/FBR/v1/api/Live/PostData",
      headers: {
        Authorization: "Bearer 1298b5eb-b252-3d97-8622-a4a69d5bf818",
        "Content-Type": "application/json",
      },
      data: data,
      httpsAgent: {
        rejectUnauthorized: false,
      },
    };

    await axios
      .request(config)
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handlePrint = async () => {
    await fbrIntegration();
    window.print();
    dispatch(SET_SELECTED_ORDER(null));
    dispatch(INITIALIZE_PRODUCT_CONSUMPTION_DETAIL());
    dispatch(SET_CUSTOMER_DATA(undefined));
    dispatch(SET_PRINTERPOPUP(false));
    if (rightBarHeader.isCheckout) dispatch(INITIALIZE_ORDER_DATA());
    if (rightBarHeader.selectedHeader === "Dine-in") {
      dispatch(SET_CURRENT_TABLE(0));
    }
    dispatch(SET_ISCHECKOUT(false));
    dispatch(SET_CASH_PAID(0));
    dispatch(SET_CARD_PAID(0));
    dispatch(SET_CREDIT_PAID(0));
    dispatch(SET_PAYMENT_REMARKS(""))
  };

  useEffect(() => {
    handlePrint();
  }, []);

  return (
    <div className="h-auto overflow-hidden max-w-[400px] w-[400px]">
      <Invoice />
    </div>
  );
}

export default PrintInvoice;
