import React, { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { RootState } from "..";
import { createNewBaseService } from "../assets/BaseService";
import { Root2, Orderdaum } from "../Interfaces/IOrderData";
import Modal from "@mui/material/Modal";
import { OrderDetails } from "../Interfaces/IOrderDetails";

const KDSOrdersPage: React.FC = () => {
  const [orders, setOrders] = useState<Root2[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedOrderDetails, setSelectedOrderDetails] =
    useState<Root2 | null>(null);
  const [openOrderDetailsModal, setOpenOrderDetailsModal] = useState(false);
  const darkMode = useSelector((state: RootState) => state.leftHeader.darkMode);
  const pos_config = useSelector(
    (state: RootState) => state.leftHeader.pos_config
  );
  const branchName = useSelector(
    (state: RootState) => state.cart.loginData.branchName
  );
  const NewBaseService = createNewBaseService(pos_config);

  const fetchOrders = async () => {
    setIsLoading(true);
    const filter: { branch: string; KDSStatus?: string } = {
      branch: branchName,
    };
    try {
      const res = await NewBaseService.get(
        "/dynamic/query/get-all-orders-with-details",
        {
          params:
          {
            collection: "orders",
            filter: filter,
          }
        }
      );
      const ordersFromAPI = res.data.data;
      const kdsSuccessOrders = ordersFromAPI.filter(
        (order: any) => order.KDSStatus === "Ready To Serve"
      );
      setOrders(kdsSuccessOrders);
    } catch (err) {
      console.error("Orders get error", err);
      setOrders([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  const createOrderJson = (status: string, item: any): OrderDetails => {
    const userName = localStorage.getItem("username")

    return {
      branch: branchName,
      code: item.ID,
      platform: "POS",
      name: item.TableName,
      status: item.POSStatus,
      kdsStatus: status,
      orderType: item.OrderType,
      area: "Test area",
      tax: item?.TaxAmount,
      voucher: item?.VoucherValue,
      discountamount: item?.Discount,
      orderamount: item?.OrderAmount,
      paymenttype: item?.PaymentType,
      paymentRemarks: item?.PaymentRemarks,
      cashAmount: item?.cashAmount,
      cardAmount: item?.cardAmount,
      creditAmount: item?.creditAmount,
      creditorName: item?.creditorName,
      totalamount: item?.SubTotal,
      taxamount: item?.TaxAmount,
      deliverycharges: 0,
      customerFullName: item.CustomerName || "",
      customerPhone: item.CustomerMobile || "",
      customerAddress: item.CustomerAddress || "",
      RiderName: "",
      RiderPhone: "",
      AssignedRiderID: "",
      DeliveryStatus: "",
      collectedby: status === "dispatched" ? "" : userName,
      customerCity: item?.City,
      customerLatitude: null,
      customerLongitude: null,
      customerOtherAddresses: null,
      orderdata: item?.orderdata.map((obj: any) => ({
        productName: obj?.ItemName,
        refCode: obj?.ItemID,
        categoryName: obj?.Category,
        discountGiven: 0,
        itemStatus: "Ready to Serve",
        minimumDelivery: obj?.MinimumDelivery,
        itemImage:
          "https://admin.dunkinpizza.com.pk/Images/ProductImages/13-inch-thumbnails.jpg",
        options:
          obj.options?.map((temp: any) => ({
            OptionID: temp?.OptionID,
            OptionName: temp?.OptionName,
            OptionGroupName: temp?.OptionGroupName,
            Price: temp?.Price,
            Quantity: temp?.Quantity,
          })) || [],
        quantity: obj?.Quantity,
        price: obj?.Price,
        totalProductPrice: obj?.orderTotal,
        SizeID: obj?.SizeID,
        Status: obj?.Status,
      })),
      Remarks: "",
      category: "",
    };
  };

  const handleDone = async (item: Root2) => {
    const order: OrderDetails = createOrderJson("Served", item);
    try {
      await NewBaseService.post("/dynamic/update", {
        collection: "orders",
        filter: { id: item.ID },
        data: order
      });
      fetchOrders();
    } catch (error) {
      console.error("Error updating order status:", error);
    }
  };

  const OrderItem: React.FC<{ item: Root2 }> = React.memo(({ item }) => (
    <div
      className={`${darkMode ? "bg-gray-800 text-white" : "bg-white text-gray-800"
        } 
      border ${darkMode ? "border-gray-700" : "border-gray-200"}
      rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 p-4 sm:p-6 md:p-8`}
    >
      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-3">
        <h3 className="font-bold text-lg sm:text-xl">Order ID: #{item.code}</h3>
        <span className="px-3 py-1 mt-2 sm:mt-0 rounded-full text-xs font-semibold bg-green-200 text-green-800">
          {item.KDSStatus}
        </span>
      </div>
      <div className="mb-3 text-sm sm:text-base">
        <p>
          <strong>Order Type:</strong> {item.OrderType}
        </p>
        <p>
          <strong>Customer:</strong> {item.CustomerName}
        </p>
      </div>
      <div className="h-24 overflow-y-auto mb-3 text-xs sm:text-sm">
        {item.orderdata &&
          Array.isArray(item.orderdata) &&
          item.orderdata.map((obj, i) => (
            <div key={i} className="flex justify-between mb-1">
              <span>{obj.ItemName}</span>
              <span>{obj.Quantity}x</span>
            </div>
          ))}
      </div>
      <div className="flex justify-between items-center mb-3 text-sm sm:text-base">
        <span className="font-semibold">Total Bill:</span>
        <span className="text-red-500 font-bold">Rs. {item.OrderAmount}</span>
      </div>
      <button
        className={`w-full py-2 rounded-md text-center transition-colors duration-300
      ${darkMode
            ? `bg-[${pos_config?.order_bar_view_details_color_darkmode}] text-[${pos_config?.order_bar_view_details_text_color_darkmode}]`
            : `bg-[${pos_config?.order_bar_view_details_color}] text-[${pos_config?.order_bar_view_details_text_color}]`
          }`}
        onClick={() => handleDone(item)}
      >
        Done
      </button>
    </div>
  ));

  return (
    <div className="p-4">
      {isLoading ? (
        <div
          className={`flex justify-center items-center h-64 ${darkMode ? "text-white" : "text-black"
            }`}
        >
          Loading...
        </div>
      ) : orders.length > 0 ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
          {orders.map((item: Root2) => (
            <OrderItem key={item.ID} item={item} />
          ))}
        </div>
      ) : (
        <div
          className={`flex justify-center items-center h-64 ${darkMode ? "text-white" : "text-black"
            }`}
        >
          No Orders Served at the moment.
        </div>
      )}
    </div>
  );
};

export default KDSOrdersPage;
