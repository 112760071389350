import { useCallback, useEffect, useState } from "react";
import {
  BaseService,
  createNewBaseService,
} from "../../../../assets/BaseService";
import { Modal } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../../..";
import { IoClose } from "react-icons/io5";
import { MdReport } from "react-icons/md";

interface ComplaintModalProps {
  open: boolean;
  onClose: () => void;
  orderId: string;
  orderDetails: any;
}

const ComplaintModal: React.FC<ComplaintModalProps> = ({
  open,
  onClose,
  orderId,
  orderDetails,
}) => {
  const darkMode = useSelector((state: RootState) => state.leftHeader.darkMode);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showThankYou, setShowThankYou] = useState(false);
  const pos_config = useSelector(
    (state: RootState) => state.leftHeader.pos_config
  );
  const NewBaseService = createNewBaseService(pos_config);

  const [formData, setFormData] = useState({
    Status: "Pending",
    Date: new Date().toISOString(),
    Name: "",
    Mobile: "",
    Complain: "",
    Type: "dine-in",
  });

  useEffect(() => {
    if (orderDetails) {
      setFormData((prev) => ({
        ...prev,
        Name: orderDetails.CustomerName || "",
        Mobile: orderDetails.CustomerMobile || "",
      }));
    }
  }, [orderDetails]);

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      await NewBaseService.post("/dynamic/create", {
        collection: "complains",
        data: {
          ...formData,
          OrderID: orderId,
          Created: new Date().toISOString(),
        },
      });
      setShowThankYou(true);
      setTimeout(() => {
        onClose();
        resetForm();
      }, 3000);
    } catch (error) {
      console.error("Error submitting complaint:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const resetForm = () => {
    setFormData({
      Status: "Pending",
      Date: new Date().toISOString(),
      Name: orderDetails?.CustomerName || "",
      Mobile: orderDetails?.CustomerMobile || "",
      Complain: "",
      Type: "dine-in",
    });
    setShowThankYou(false);
  };

  const inputClasses = `w-full p-4 rounded-lg border ${
    darkMode
      ? "bg-gray-700 border-gray-600 focus:border-blue-500"
      : "bg-white border-gray-200 focus:border-blue-500"
  } transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50`;

  const labelClasses = "block text-sm font-semibold mb-2 text-opacity-90";

  const handleKeyDown = (e: any) => {
    if (e.key === " ") {
      e.stopPropagation();
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <div
        className={`${
          darkMode ? "bg-gray-800 text-white" : "bg-white text-gray-800"
        } 
                rounded-2xl shadow-2xl p-8 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 
                w-[90%] max-w-2xl max-h-[90vh] overflow-y-auto border ${
                  darkMode ? "border-gray-700" : "border-gray-200"
                }`}
      >
        {!showThankYou ? (
          <>
            <div className="flex justify-between items-start mb-8">
              <div>
                <div className="flex items-center gap-3 mb-2">
                  <MdReport className="text-red-500 text-3xl" />
                  <h2 className="text-3xl font-bold">Submit Complaint</h2>
                </div>
                <div className="space-y-1 bg-opacity-10 p-3 rounded-lg bg-blue-500 mt-3">
                  <p className="text-sm">Order #{orderDetails?.ID}</p>
                  <p className="text-sm">
                    Order Amount: Rs. {orderDetails?.OrderAmount || "0"}
                  </p>
                </div>
              </div>
              <button
                onClick={onClose}
                className={`p-2 rounded-full hover:bg-opacity-10 hover:bg-red-500 transition-colors`}
              >
                <IoClose size={24} />
              </button>
            </div>

            <div className="space-y-6">
              <div className="grid grid-cols-2 gap-6">
                <div>
                  <label className={labelClasses}>Status</label>
                  <select
                    value={formData.Status}
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        Status: e.target.value,
                      }))
                    }
                    className={inputClasses}
                  >
                    <option value="Pending">Pending</option>
                    <option value="In Progress">In Progress</option>
                    <option value="Resolved">Resolved</option>
                  </select>
                </div>

                <div>
                  <label className={labelClasses}>Category</label>
                  <select
                    value={formData.Type}
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        Type: e.target.value,
                      }))
                    }
                    className={inputClasses}
                  >
                    <option value="dine in">Dine In</option>
                    <option value="delivery">Delivery</option>
                    <option value="takeaway">Takeaway</option>
                  </select>
                </div>
              </div>

              <div className="grid grid-cols-2 gap-6">
                <div>
                  <label className={labelClasses}>Name</label>
                  <input
                    type="text"
                    value={formData.Name}
                    onChange={(e) =>
                      setFormData((prev) => ({ ...prev, Name: e.target.value }))
                    }
                    readOnly={!!orderDetails?.CustomerName}
                    className={inputClasses}
                    placeholder="Enter name"
                  />
                </div>

                <div>
                  <label className={labelClasses}>Phone Number</label>
                  <input
                    type="text"
                    value={formData.Mobile}
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        Mobile: e.target.value,
                      }))
                    }
                    readOnly={!!orderDetails?.CustomerPhoneNumber}
                    className={inputClasses}
                    placeholder="Enter phone number"
                  />
                </div>
              </div>

              <div>
                <label className={labelClasses}>Complaint Details</label>
                <textarea
                  value={formData.Complain}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      Complain: e.target.value,
                    }))
                  }
                  onKeyDown={handleKeyDown}
                  className={`${inputClasses} min-h-[150px] resize-none`}
                  placeholder="Please describe your complaint in detail..."
                />
              </div>

              <button
                onClick={handleSubmit}
                disabled={isLoading || !formData.Complain}
                className={`w-full py-4 rounded-xl font-semibold transition-all duration-300 
                                ${
                                  isLoading || !formData.Complain
                                    ? "bg-gray-400 cursor-not-allowed"
                                    : "bg-blue-500 hover:bg-blue-600 hover:shadow-lg"
                                } 
                                text-white transform hover:-translate-y-1`}
              >
                {isLoading ? "Submitting..." : "Submit Complaint"}
              </button>
            </div>
          </>
        ) : (
          <div className="text-center py-12">
            <div className="w-20 h-20 mx-auto mb-6 rounded-full bg-green-100 flex items-center justify-center transform scale-110 transition-transform duration-500">
              <svg
                className="w-10 h-10 text-green-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M5 13l4 4L19 7"
                />
              </svg>
            </div>
            <h2 className="text-3xl font-bold mb-4">Complaint Submitted</h2>
            <p className="text-lg opacity-75">
              We will address your concerns promptly.
            </p>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default ComplaintModal;
