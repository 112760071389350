import React, { useEffect, useRef, useState } from "react";
import search from "../../../assets/RightBar/Search.png";
import filter from "../../../assets/RightBar/Filter.png";
import sort from "../../../assets/RightBar/Sort.png";
import { useDispatch, useSelector } from "react-redux";
import { SET_BUTTON_CLICKED, SET_MOBILE_SEARCH, SET_ORDER_ID_SEARCH, SET_SEARCHED_ORDER } from "../../../Features/cart";
import SelectOrderFilter from "./SelectOrderFilter";
import { BiPhone, BiSearch } from "react-icons/bi";
import { RootState } from "../../..";
import { FaSync } from "react-icons/fa";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { SET_DATE_RANGE } from "../../../Features/cart";
import { IoMdClose } from "react-icons/io";

const OrderHeader = () => {
  const dispatch = useDispatch();
  const darkMode = useSelector((state: RootState) => state.leftHeader.darkMode);
  const dateRange = useSelector((state: RootState) => state.cart.dateRange);

  const mobileSearchValue = useSelector((state: RootState) => state.cart.mobileSearch);



  useEffect(() => {
    // Load saved filters on component mount
    const savedMobileSearch = localStorage.getItem('mobileSearch') || '';
    const savedOrderIdSearch = localStorage.getItem('orderIdSearch') || '';
    const savedDateRange = JSON.parse(localStorage.getItem('dateRange') || 'null');

    setMobileSearch(savedMobileSearch);
    setOrderIdSearch(savedOrderIdSearch);
    dispatch(SET_MOBILE_SEARCH(savedMobileSearch));
    dispatch(SET_ORDER_ID_SEARCH(savedOrderIdSearch));
    if (savedDateRange) {
      dispatch(SET_DATE_RANGE({
        startDate: savedDateRange.startDate ? new Date(savedDateRange.startDate) : null,
        endDate: savedDateRange.endDate ? new Date(savedDateRange.endDate) : null
      }));
    }
  }, []);


  const [mobileSearch, setMobileSearch] = useState("");
  const [orderIdSearch, setOrderIdSearch] = useState("");
  const handleOrderIdSearch = (e: any) => {
    const value = e.target.value;
    setOrderIdSearch(value);
    dispatch(SET_ORDER_ID_SEARCH(value));
    localStorage.setItem('orderIdSearch', value);
  };

  const handleMobileSearch = (e: any) => {
    const value = e.target.value;
    setMobileSearch(value);
    dispatch(SET_MOBILE_SEARCH(value));
    localStorage.setItem('mobileSearch', value);
  };

  const ref = useRef(null);

  return (
    <div className={`
      ${darkMode ? "text-white bg-gray-900" : "text-gray-800 bg-gray-50"}
      w-full p-4 rounded-lg shadow-md
    `}>
      <div className="flex items-center justify-between gap-4">
        {/* Search Fields Container */}
        <div className="flex items-center gap-3 flex-1">
          {/* Order ID Search */}
          <div className="flex-1 min-w-[180px]">
            <div className={`
              ${darkMode ? "bg-gray-800" : "bg-white"}
              flex items-center rounded-lg px-3 h-10
              border ${darkMode ? "border-gray-700" : "border-gray-200"}
            `}>
              <BiSearch className="text-gray-400" />
              <input
                ref={ref}
                onChange={handleOrderIdSearch}
                value={orderIdSearch}
                className={`${darkMode ? "bg-gray-800 text-white" : "bg-white text-gray-800"}
                  w-full ml-2 outline-none text-sm`}
                placeholder="Order ID"
              />
            </div>
          </div>

          {/* Mobile Search */}
          <div className="flex-1 min-w-[180px]">
            <div className={`
              ${darkMode ? "bg-gray-800" : "bg-white"}
              flex items-center rounded-lg px-3 h-10
              border ${darkMode ? "border-gray-700" : "border-gray-200"}
            `}>
              <BiPhone className="text-gray-400" />
              <input
                onChange={handleMobileSearch}
                value={mobileSearch}
                className={`${darkMode ? "bg-gray-800 text-white" : "bg-white text-gray-800"}
                  w-full ml-2 outline-none text-sm`}
                placeholder="Mobile Number"
              />
            </div>
          </div>

          {/* Date Range Picker */}
          <div className="flex-1 min-w-[180px]">
            <div className={`
              ${darkMode ? "bg-gray-800" : "bg-white"}
              flex items-center rounded-lg px-3 h-10 w-full 
              border ${darkMode ? "border-gray-700" : "border-gray-200"}
            `}>
              <DatePicker
                selectsRange={true}
                startDate={dateRange.startDate || undefined}
                endDate={dateRange.endDate || undefined}
                onChange={(dates) => {
                  dispatch(SET_DATE_RANGE({
                    startDate: dates[0],
                    endDate: dates[1]
                  }));
                  localStorage.setItem('dateRange', JSON.stringify({
                    startDate: dates[0],
                    endDate: dates[1]
                  }));
                }}
                placeholderText="Date Range"
                className={`${darkMode ? "bg-gray-800 text-white" : "bg-white"} w-full outline-none text-sm`}
              />
            </div>
          </div>
        </div>

        {/* Filters and Search Button */}
        <div className="flex items-center gap-3">
          <SelectOrderFilter />
          <button
            onClick={() => dispatch(SET_BUTTON_CLICKED(true))}
            className={`
              h-10 px-6 rounded-lg font-medium text-white whitespace-nowrap
              ${darkMode ? 'bg-blue-600 hover:bg-blue-700' : 'bg-blue-500 hover:bg-blue-600'}
            `}
          >
            <BiSearch className="inline-block mr-2" />
            Search
          </button>
        </div>
      </div>
    </div>
  );
};

export default OrderHeader;
