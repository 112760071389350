import React from "react";
import { MdTableRestaurant } from "react-icons/md";
import { AiFillCheckCircle } from "react-icons/ai";
import round from "../../../assets/RightBar/Ellipse 243.png";
import { SET_SELECTEDHEADER, SET_VISUAL_HEADER } from "../../../Features/rightBarHeader";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../..";

interface TableProps {
  index: number;
  tableName: string;
  active: number | null;
  status: string;
  orderStatus: string | null;
  isSelected: boolean;
  seating: string;
}

const Table: React.FC<TableProps> = ({ index, tableName, active, status, orderStatus, isSelected, seating }) => {
  const darkMode = useSelector((state: RootState) => state.leftHeader.darkMode);
  const dispatch = useDispatch();
  const pos_config = useSelector((state: RootState) => state.leftHeader.pos_config);

  const isHold = orderStatus === "hold";
  const availabilityStatus = isHold ? "In Progress" : "Available";

  const getBackgroundColor = () => {
    if (isSelected) return "#e0f2fe";
    if (isHold) return "#ffb703";
    if (darkMode) return "var(--table-button-color-darkmode)";
    return "var(--table-button-color)";
  };

  const getTextColor = () => {
    if (isHold) return "#2b2c37";
    if (darkMode) return "var(--table-button-text-color-darkmode)";
    return "var(--table-button-text-color)";
  };

  return (
    <div
      className={`
        relative flex flex-col items-center justify-between
        w-full h-32 p-3 rounded-lg shadow-md transition-all duration-200
        ${isSelected ? "ring-2 ring-blue-500" : ""}
        hover:scale-105 hover:shadow-lg cursor-pointer
        ${darkMode ? "border border-gray-400" : "border border-gray-800"}
      `}
      style={{
        backgroundColor: getBackgroundColor(),
        color: getTextColor(),
      }}
      onClick={() => {
        dispatch(SET_SELECTEDHEADER("Pickup"))
        dispatch(SET_VISUAL_HEADER("Dine-in"))
      }}
    >
      <div className="absolute top-2 right-2 flex items-center gap-1 text-xs">
        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
          <path fillRule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clipRule="evenodd" />
        </svg>
        <span>x {seating}</span>
      </div>
      {isSelected && (
        <div className="absolute top-2 right-2 text-blue-500">
          <AiFillCheckCircle size={20} />
        </div>
      )}
      <div className={`
        flex items-center justify-center px-2 py-1 rounded-full text-xs font-bold
        ${isHold ? "bg-yellow-500 text-yellow-900" : "bg-green-500 text-green-900"}
      `}>
        {availabilityStatus}
      </div>
      <MdTableRestaurant className="h-12 w-12 mb-1" />
      <div className="text-sm font-medium">Table {tableName}</div>
    </div>
  );
};

export default Table;
