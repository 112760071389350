import React, { useEffect, useRef, useState } from "react";
import PosPage from "./Pages/PosPage";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { createMenu } from "./Features/orderMenu";
import { error } from "console";
import { INITIALIZE_TABLE_AREA } from "./Features/cart";
import { RootState } from ".";
import { SET_DB } from "./Features/database";
// @ts-ignore
import PouchDB from "pouchdb";
import data from "./BW Complete Menu.json";
import LoginPage from "./Pages/LoginPage";
import KDSPage from "./Pages/KDSPage";
import { SET_POS_CONFIG, SET_SHORTCUTS_CONFIG } from "./Features/leftBarHeader";
import BarcodePrintPage from "./Pages/BarcodePrintPage";
import { IPosConfig } from "./Interfaces/IPosConfig";
import { BaseService } from "./assets/BaseService";

function App(): JSX.Element {
  const appRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const db = useSelector((state: RootState) => state.database.db);
  const route = useSelector((state: RootState) => state.header.route);
  const slug = useSelector((state: RootState) => state.leftHeader.slug);
  const token = localStorage.getItem("access_token");
  const selectedHeader = useSelector(
    (state: RootState) => state.leftHeader.selectedHeader
  );

  const screenFull =
    selectedHeader === "Orders";

  const snake_case_string = (str: string | null) => {
    return (
      str &&
      str
        .match(
          /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g
        )
        ?.map((s: string) => s.toLowerCase())
        .join("_")
    );
  };

  const convertJsonToFormat = (jsonData: IPosConfig) => {
    console.log("jsonData", jsonData);
    const result: any = {};
    if (jsonData !== undefined) {
      let keys = Object.keys(jsonData);
      for (let i = 0; i < keys.length; i++) {
        result[`${snake_case_string(keys[i].toString())}`] =
          jsonData[keys[i] as keyof IPosConfig];
      }
    }

    return result;
  };

  useEffect(() => {
    const tokenAPIFunction = async () => {
      // await axios
      //   .post(
      //     "https://mb6sjvl84i.execute-api.ap-southeast-1.amazonaws.com/dev/dynamic/get",
      //     {
      //       data: '{"collection":"taxonomy","filter":{"name":"pos-config-new"},"select":{"name":1,"keyValuePair":1}}',
      //     },
      //     {
      //       headers: {
      //         Authorization: `Bearer ${token}`,
      //       },
      //     }
      //   )

      await BaseService.post(
        "https://mb6sjvl84i.execute-api.ap-southeast-1.amazonaws.com/dev/dynamic/get",
        { collection: "pos-config" },
        {}
      ).then((res) => {
        console.log(res.data);
        const formattedData = convertJsonToFormat(res.data.data[0]);
        console.log("formatted Data", formattedData);
        dispatch(SET_POS_CONFIG(formattedData));
      });
      await BaseService.post(
        "/dynamic/get",
        {
          collection: "taxonomy",
          filter: { name: "Shortcuts Config" },
          select: { name: 1, keyValuePair: 1 },
        },
        {}
      ).then((res) => {
        const formattedData = convertJsonToFormat(res.data);
        console.log("Shortcuts", formattedData);
        dispatch(SET_SHORTCUTS_CONFIG(formattedData));
      });
      // await axios
      //   .post(
      //     "https://mb6sjvl84i.execute-api.ap-southeast-1.amazonaws.com/dev/dynamic/query/get-menu-by-id?menuId=64be39dea8e961707a399716",
      //     {},
      //     {
      //       headers: {
      //         Authorization: `Bearer ${token}`,
      //       },
      //     }
      //   )
      //   .then((res) => {
      //     console.log("resssss", res.data);
      //     dispatch(createMenu(res.data));
      //     dispatch(INITIALIZE_TABLE_AREA(7));
      //   })
      //   .catch((err) => {
      //     console.log("Menu get error", err);
      //   });
      // await axios
      //   //https://services.belcochocolates.com/BelcoAPI.aspx?Method=GetMenu
      //   //https://consoleservice.cloubuzz.com/Services.aspx?Method=GetCompleteMenu
      //   .get(
      //     "https://consoleservice.cloubuzz.com/Services.aspx?Method=GetCompleteMenu",
      //     {
      //       headers: {
      //         slug: slug,
      //       },
      //     }
      //   )
      //   .then((result) => {
      //     if (result.data) {
      //       console.log(result.data);
      //       let obj: any;
      //       obj = result.data.Data.NestedMenuForMobile[0].MenuCategoryList;

      //       result.data.Data.NestedMenuForMobile[0].MenuCategoryList.map(
      //         (val: any, i: number) => {
      //           val.MenuItemsList.map((innerVal: any, j: number) => {
      //             obj[i].MenuItemsList[j].MenuSizesList = JSON.parse(
      //               innerVal.MenuSizesList
      //             );
      //           });
      //         }
      //       );
      //       console.log("obj", obj);
      //       dispatch(createMenu(obj));
      //       dispatch(INITIALIZE_TABLE_AREA(7));
      //     } else {
      //       console.log("no data");
      //     }
      //   })
      //   .catch((error) => {
      //     console.log("no reply");
      //     console.log(error);
      //   });
    };
    tokenAPIFunction();
  }, [token]);

  const fun = async () => {
    //console.log("token", token);
    // await axios
    //   .post(
    //     "https://mb6sjvl84i.execute-api.ap-southeast-1.amazonaws.com/dev/dynamic/get",
    //     {
    //       data: '{"collection":"taxonomy","filter":{"name":"pos-config-new"},"select":{"name":1,"keyValuePair":1}}',
    //     },
    //     {
    //       headers: {
    //         Authorization: `Bearer ${token}`,
    //       },
    //     }
    //   )
    //   .then((res) => {
    //     const formattedData = convertJsonToFormat(res.data);
    //     console.log("formatted Data", formattedData);
    //     dispatch(SET_POS_CONFIG(formattedData));
    //   });
  };

  useEffect(() => {
    if (db === null) {
      dispatch(SET_DB(new PouchDB("orders")));
    }

    fun();
  }, []);

  return (
    <div className={`h-[100%] ${screenFull ? "" : "md:h-screen"}`}>
      <div className="bg-white h-[100%]" ref={appRef}>
        {route === "" && <LoginPage />}
        {route.search("/PosPage") >= 0 && <PosPage elementRef={appRef} />}
      </div>
    </div>
  );
}

export default App;

// data: '{"collection":"FeedbackApp","filter":{"slug":"feedback"}}',
